import * as models from 'models/index';
import { AppStateProvider } from 'store/index';
import * as cmsHelpers from 'util/cms-helpers';
import { normalizeStyles, sanitizePrefixedData, checkIfTrue } from 'util/helpers';
import api from 'util/api';

export function handleGeoData(this: AppStateProvider, data: null | models.store.IRegionCheck): void {
  const cmsProps: models.base.IGenericObject = { ...this.state.cmsProps }
  cmsProps.inRegion = !data || !data.whitelist || cmsHelpers.checkIfInRegion(data)

  const { globalProps } = this.state;

  this.setState((prevState: models.store.IAppProps) => {
    return {
      ...prevState,
      cmsProps,
      globalProps: {
        ...globalProps,
        userData: {
          ...globalProps.userData,
          country: data ? data.userCountry : '',
          state: data ? data.userRegion : ''
        }
      }
    };
  });
};

export function storeCmsData(this: AppStateProvider, cmsData: models.api.ICmsData): void {
  const isCategoryVote = checkIfTrue(cmsData.settings.enable_category_vote);

  this.setState((prev: models.store.IAppProps) => {
    return {
      ...prev,
      cmsData,
      isCategoryVote
    };
  });
};

export function storeData(this: AppStateProvider, data: {
      stylesData?: models.api.ICmsData,
      copyData?: models.api.ICmsData,
      contestantsData?: models.api.ICmsData }): void
  {
  const cmsProps: models.base.IGenericObject = { ...this.state.cmsProps }

  if (data.stylesData) {
    data.stylesData = normalizeStyles(data.stylesData);
  }

  if (data.contestantsData) {
    // Handle data language support
    const { lang = 'en' } = api.storage.qsps;
    const languageSupportData = sanitizePrefixedData(data.contestantsData.data, lang);
    data.contestantsData.data = languageSupportData;
  }

  cmsProps.isAppReady = true;

  this.setState((prev: models.store.IAppProps) => {
    return {
      ...prev,
      ...data,
      cmsProps
    };
  });
};
