import * as React from 'react';
import * as models from 'models/index';

function Video(props: models.video.IVideoProps) {

  function getId(url: string): string {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);

    if (match && match[2].length == 11) {
        return match[2];
    } else {
        return 'error';
    }
  }

  const videoId = getId(props.url);

  return (
    <iframe width="560" height="315" src={`https://www.youtube.com/embed/${videoId}`}
    frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen></iframe>
  )

}

export default Video;
