import * as React from 'react';
import * as constants from 'util/constants';
import * as models from 'models/index';
import * as auth from 'store/auth';
import * as api from 'store/api';
import * as cms from 'store/cms';
import * as global from 'store/global';
import * as loading from 'store/loading';
import * as login from 'store/login';
import * as modal from 'store/modal';
import * as grid from 'store/grid';
import * as terms from 'store/terms';
import * as vote from 'store/vote';

const { Consumer, Provider } = React.createContext(
  {} as models.store.IAppProps
);

class AppStateProvider extends React.Component {
  public state: models.store.IAppProps = {
    globalProps: {
      category: {},
      contestants: [],
      contestantId: '',
      userData: {
        country: '',
        email: '',
        facebookId: '',
        isAuthorized: false,
        method: '',
        name: '',
        state: '',
        fb_email: '',
        fb_first_name: '',
        fb_last_name: '',
        twitter_handle: '',
        twitter_id: ''
      }
    },

    globalFn: {
      setContestantId: global.setContestantId.bind(this),
      setPym: global.setPym.bind(this),
      scrollToTop: global.scrollToTop.bind(this),
      storePosition: global.storePosition.bind(this),
      scrollToStoredPosition: global.scrollToStoredPosition.bind(this)
    },

    apiFn: {
      getVotePayload: api.getVotePayload.bind(this),
      getUserPayload: api.getUserPayload.bind(this),
      getHistoryPayload: api.getHistoryPayload.bind(this),
    },

    authFn: {
      loginViaEmail: auth.loginViaEmail.bind(this),
      loginViaFacebook: auth.loginViaFacebook.bind(this),
      loginViaTwitter: auth.loginViaTwitter.bind(this),
      logoffEmail: auth.logoffEmail.bind(this),
      logoffFacebook: auth.logoffFacebook.bind(this),
      logoffTwitter: auth.logoffTwitter.bind(this),
      submitLogin: auth.submitLogin.bind(this),
    },

    cmsData: {},

    copyData: {},

    contestantsData: {},

    cmsFn: {
      handleGeoData: cms.handleGeoData.bind(this),
      storeCmsData: cms.storeCmsData.bind(this),
      storeData: cms.storeData.bind(this)
    },

    cmsProps: {
      inRegion: true,
      isAppReady: false
    },

    loadingFn: {
      setDescription: loading.setDescription.bind(this),
      setTitle: loading.setTitle.bind(this)
    },

    loadingProps: {
      description: '',
      title: '',
      timeout: 0
    },

    loginFn: {
      updateRetryFb: login.updateRetryFb.bind(this)
    },

    loginProps: {
      shouldRetryFb: false
    },

    match: {},

    modalFn: {
      closeModal: modal.closeModal.bind(this),
      openModal: modal.openModal.bind(this),
      setNestedModal: modal.setNestedModal.bind(this)
    },

    modalProps: {
      type: '',
      nested: ''
    },

    gridFn: {
      toggleSort: grid.toggleSort.bind(this),
      createContestantList: grid.createContestantList.bind(this),
      setCategory: grid.setCategory.bind(this),
      getContestantById: grid.getContestantById.bind(this),
      displayContestantModal: grid.displayContestantModal.bind(this)
    },

    gridProps: {
      sortingMethod: constants.ALPHABETICAL,
      sortingDirection: constants.ASCENDING,
    },

    isCategoryVote: false,

    pym: null,

    termsFn: {
      updateTerms: terms.updateTerms.bind(this)
    },

    termsProps: {},

    stylesData: {},

    voteFn: {
      submitVote: vote.submitVote.bind(this),
      setVoteHistory: vote.setVoteHistory.bind(this),
    }
  };

  public render(): React.ReactNode {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

const Connect = (Component: typeof React.Component | ((props: any) => any) ) => {
  return (props: models.base.IGenericObject) => {
    return <Consumer>{data => <Component {...data} {...props} />}</Consumer>;
  };
};

export { Connect, AppStateProvider };
