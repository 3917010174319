import * as models from 'models/index';
import api from 'util/api';

export const checkGeo = (cmsData: models.api.ICmsData): Promise<null | models.store.IRegionCheck> => {
  const countries = cmsData.settings.countries
    .replace(/ /g, '')
    .split(',')
    .filter((item: string) => item.length);

  return api.fetchGeo().then((geoData: models.api.IGeoData) => {
    return {
      userCountry: geoData.geoheaders.country,
      whitelist: countries.length? countries : null,
      userRegion: geoData.geoheaders.region
    };
  });
};

export const checkIfInRegion = (data: null | models.store.IRegionCheck) => {
  if (!data) { return; }
  const { userCountry, whitelist } = data;
  return whitelist.indexOf(userCountry) >= 0;
};
