import { getQueryParamByName } from 'util/helpers';
// @ts-ignore
import TLDRC from 'tldr-child';

export default function pymLoader() {
  if (window.self !== window.top) {
    const pymParentId = getQueryParamByName('childId') || 'telescopeApp';
    const pym = new TLDRC.Child({
      polling: 500,
      id: pymParentId
    });
    return pym
  } else {
    return null;
  }
}
