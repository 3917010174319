import * as models from 'models/index';
import { media } from 'util/style-variables';
import { StyleSheet } from 'aphrodite/no-important';

export const style: any = (styles: models.base.IGenericObject) => StyleSheet.create({
  button: {
    backgroundColor: 'black',
    color: '#fff',
    fontSize: '12px',
    minWidth: '100px',
    minHeight: 50,
    marginBottom: styles.options.pushDown ? '10px' : '0',
    padding: '10px',
    ...styles.options.globalStyles,
    ...styles.buttonStyles,

    [media.tablet]: {
      ...styles.buttonStyles.tablet,
    },

    [media.desktop]: {
      ...styles.buttonStyles.desktop,
    }
  }
});
