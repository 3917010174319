import * as models from 'models/index';
import { StyleSheet } from 'aphrodite/no-important';
import { children, darkenColor, media } from 'util/style-variables';

export const style: any = (styles: models.base.IGenericObject) => StyleSheet.create({
  login: {
    display: 'flex',
    flexDirection: 'column',
    padding: '25px',

    [children('> *')]: {
      maxWidth: '320px',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%'
    },

    [media.tablet]: {
      padding: '55px 45px 25px',
    }
  },

  loginForm: {
    display: 'flex',
    flexDirection: 'column-reverse'
  },

  buttonsGroup: {
    width: '100%'
  },

  headline: {
    textAlign: 'center',
    paddingBottom: 20,
    ...styles.globalStyles.headlines,
    ...styles.loginStyles.headline,

    [media.tablet]: {
      ...styles.globalStyles.headlines.tablet,
      ...styles.loginStyles.headline.tablet
    },

    [media.desktop]: {
      ...styles.globalStyles.headlines.desktop,
      ...styles.loginStyles.headline.desktop
    }
  },

  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    marginBottom: 15,
    cursor: styles.areButtonsEnabled ? 'pointer' : 'default',
    padding: '8px',
    pointerEvents: styles.areButtonsEnabled ? 'auto' : 'none',
    width: '100%',
    minHeight: 50,
    minWidth: 120,

    [children('span')]: {
      padding: 0
    },

    ':last-child': {
      marginBottom: 0
    }
  },

  email_button: {
    ...styles.buttonStyles.email,
    ...(!styles.areButtonsEnabled? {
      background: '#545454',
      borderColor: '#545454'
    }: {}),

    [media.tablet]: {
      ...styles.buttonStyles.email.tablet,
    },

    [media.desktop]: {
      ...styles.buttonStyles.email.desktop,
    }
  },

  facebook_button: {
    lineHeight: '1',
    ...styles.buttonStyles.facebook,
    ...(!styles.areButtonsEnabled? {
      opacity: .7
    }: {}),

    [children('svg')]: {
      fill: '#fff',
      height: '20px',
      transform: 'translateY(1px)'
    },

    [children('> span')]: {
      display: 'inline-block',
      marginRight: '15px',
      verticalAlign: 'text-top'
    },

    ':hover': {
      background: darkenColor(styles.buttonStyles.facebook.background)
    },

    ':focus': {
      background: darkenColor(styles.buttonStyles.facebook.background)
    }
  },

  twitter_button: {
    backgroundColor: '#1ea1f2',
    color: '#fff',
    ...styles.buttonStyles.twitter,
    ...(!styles.areButtonsEnabled? {
      opacity: .7
    }: {}),

    [children('svg')]: {
      fill: '#fff',
      height: 20,
      width: 25,
      transform: 'translateY(2px)'
    },

    [children('> span')]: {
      display: 'inline-block',
      marginRight: 15,
      marginLeft: -15,
      verticalAlign: 'text-top'
    },
  },

  divider: {
    display: 'flex',
    alignItems: 'center',
    margin: '20px 0 24px'
  },

  divider_line: {
    flexGrow: 1,
    height: '1px',
    backgroundColor: '#ccc'
  },

  divider_text: {
    padding: '0 15px',
    fontWeight: 'bold',
    fontSize: '16px'
  },

  email_input: {
    border: styles.hasEmailError? '2px solid #C70000' : '2px solid #000',
    fontSize: '16px',
    height: '36px',
    padding: '0 10px',
    width: '100%'
  },

  email_error: {
    color: '#E34530',
    display: styles.hasEmailError ? 'block' : 'none',
    fontSize: '13px',
    margin: '10px auto',
    textAlign: 'left',
    ...styles.loginStyles.error
  },

  email_cta: {
    ...styles.loginStyles.emailCta
  }
});
