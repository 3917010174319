import 'react-app-polyfill/ie9';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';

import App from 'components/app';
import { AppStateProvider } from 'store/index';
import history from 'util/history';
import 'focus-visible';
import './style.css';

// TODO: move routes to app component and render each component based on path
ReactDOM.render(

  <Router history={history}>
    <AppStateProvider>
      <App />
    </AppStateProvider>
  </Router>,

  document.getElementById('root') as HTMLElement);
