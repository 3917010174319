import * as models from 'models/index';
import { media, children } from 'util/style-variables';
import { StyleSheet } from 'aphrodite/no-important';

export const style: any = (styles: models.base.IGenericObject) => StyleSheet.create({
  panel_wrapper: {
    display: 'flex',
    width: `${(100 / styles.columns.mobile)}%`,

    [media.tablet]: {
      width: `${(100 / styles.columns.tablet)}%`,
    },

    [media.desktop]: {
      width: `${(100 / styles.columns.desktop)}%`,
    }
  },

  headline: {
    padding: 0,
    lineHeight: '1.1',
    ...styles.globalStyles.headlines,
    ...styles.panelStyles.headline,

    [media.tablet]: {
      ...styles.globalStyles.headlines.tablet,
      ...styles.panelStyles.headline.tablet
    },

    [media.desktop]: {
      ...styles.globalStyles.headlines.desktop,
      ...styles.panelStyles.headline.desktop
    }
  },

  description_1: {
    width: '100%',
    ...styles.panelStyles.description_1,

    [media.tablet]: {
      fontSize: '16px',
      ...styles.panelStyles.description_1.tablet
    },

    [media.desktop]: {
      ...styles.panelStyles.description_1.desktop
    }
  },

  description_2: {
    width: '100%',
    ...styles.panelStyles.description_2,

    [media.tablet]: {
      fontSize: '14px',
      ...styles.panelStyles.description_2.tablet
    },

    [media.desktop]: {
      ...styles.panelStyles.description_2.desktop
    }
  },

  description_3: {
    width: '100%',
    ...styles.panelStyles.description_3,

    [media.tablet]: {
      fontSize: '12px',
      ...styles.panelStyles.description_3.tablet
    },

    [media.desktop]: {
      ...styles.panelStyles.description_3.desktop
    }
  },

  panel: {
    display: 'flex',
    backgroundColor: '#fff',
    flexDirection: 'column',
    margin: '10px 15px',
    width: '100%',
    ...styles.panelStyles.general,

    [children('img')]: {
      margin: '0 auto',
      width: '100%',
      flexShrink: 0,

      ':last-child': {
        height: '100%'
      }
    },

    [media.tablet]: {
      margin: 10,
      ...styles.panelStyles.general.tablet,
    },

    [media.tablet]: {
      margin: 10,
      ...styles.panelStyles.general.desktop,
    },
  },

  text_wrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    padding: '20px 20px 22px',
    textAlign: 'center',
    width: '100%'
  },

  winner_banner: {
    width: '100%',
    padding: 10,
    marginBottom: 10,
    textAlign: 'center',
    ...styles.panelStyles.winnerBanner,

    [media.tablet]: {
      ...styles.panelStyles.winnerBanner.tablet
    },

    [media.desktop]: {
      ...styles.panelStyles.winnerBanner.desktop
    }
  }
});
