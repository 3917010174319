import * as models from 'models/index';
import { StyleSheet } from 'aphrodite/no-important';
import { children } from 'util/style-variables';

export const style: any = (styles: models.base.IGenericObject) => StyleSheet.create({
  terms_group: {

  },

  term: {
    display: 'flex',
    flexDirection: 'row',
    lineHeight: 1.5,
    marginBottom: 10,
    position: 'relative',
    width: '100%',
    paddingTop: '20px',

    ':last-child': {
      marginBottom: 0,
    }
  },

  label: {
    cursor: 'pointer',
    flexGrow: 1,
    position: 'relative',
    paddingLeft: '25px',

    [children('a')]: {
      margin: 0,
      ...styles.termsStyles.optInLink
    },

    ':before': {
      backgroundColor: '#eee',
      background: styles.checkedTerms? styles.termsStyles.checkbox.checked.background : styles.termsStyles.checkbox.unchecked.background,
      border:  styles.checkedTerms?
        styles.termsStyles.checkbox.checked.border :
        styles.termsStyles.checkbox.unchecked.border,
      content: '""',
      height: '15px',
      left: 0,
      position: 'absolute',
      top: '3px',
      width: '15px'
    },

    ':after': {
      border: 'none',
      borderBottom: '2px solid #000',
      borderLeft: '2px solid #000',
      borderColor: styles.termsStyles.checkbox.checked.color,
      content: '""',
      display: styles.checkedTerms ? 'block' : 'none',
      height: '4px',
      left: '3px',
      position: 'absolute',
      top: '7px',
      transform: 'rotate(-45deg)',
      width: '9px'
    }
  },

  hasError: {
    ':before': {
      borderColor: '#C70000'
    },
    ':after': {
      borderColor: '#C70000'
    }
  },

  copy: {
    ...styles.termsStyles.optIn
  },

  error: {
    color: 'red',
    marginTop: '-5px',
    paddingLeft: '30px',
    display: !styles.checkedTerms ? 'block' : 'none',
    ...styles.termsStyles.error
  }
});
