import { AppStateProvider } from 'store/index';
import api from 'util/api';
import { ACTION_TYPES, AUTH_METHODS } from 'util/constants';

export function getVotePayload(this: AppStateProvider, id: string, isCategoryVote: boolean, catId?: string): any {
  const userPayload = this.state.apiFn.getUserPayload();
  
  return {
    action_type: ACTION_TYPES.VOTE,
    apiKey: this.state.cmsData.settings.apiKey,
    timestamp: new Date().getTime(),
    ...userPayload,
    ...(isCategoryVote? {
      category: `cat${catId}`,
      contestant: id
    } : {
      v: id
    })
  };
}

export function getUserPayload(this: AppStateProvider): any {
  const optIns = Object.keys(this.state.termsProps).reduce((storage, optIn) => {
    if (optIn) {
      storage[`optin-${optIn}`] = this.state.termsProps[optIn].isChecked ? '1' : '0';
      return storage;
    } else return storage;
  }, Object.create(null));

  const { country, method, state, email, fb_email, fb_first_name, fb_last_name, twitter_handle, twitter_id } = this.state.globalProps.userData;

  return {
    country,
    method,
    state,
    user_id: email,
    ...optIns,
    ...(
    method === AUTH_METHODS.FACEBOOK? {
      fb_email,
      fb_first_name,
      fb_last_name
    } :
    method === AUTH_METHODS.TWITTER? {
      twitter_handle,
      twitter_id
    } :
    {})
  };
}

export function getHistoryPayload(this: AppStateProvider, catId: string): any {
  return {
    action_type: ACTION_TYPES.GET,
    apiKey: this.state.cmsData.settings.apiKey,
    method: this.state.globalProps.userData.method,
    timestamp: new Date().getTime(),
    user_id: this.state.globalProps.userData.email,
    category: `cat${catId}`
  };
}
