import * as React from 'react';
import { css } from 'aphrodite/no-important';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import AdUnit from 'components/ad-unit/index';
import Categories from 'components/categories/index';
import Closed from 'components/closed/index';
import Confirmation from 'components/thanks/index';
import Countdown from 'components/countdown/index';
import ErrorFbPermissions from 'components/error-fb-permissions/index';
import ErrorMessage from 'components/error-message/index';
import Footer from 'components/footer/index';
import Header from 'components/header/index';
import Loading from 'components/loading/index';
import Login from 'components/login/index';
import { Navigation, AllCatButton } from 'components/navigation/index';
import Modal from 'components/modal/index';
import Grid from 'components/grid/index';
import Share from 'components/share/index';
import Terms from 'components/terms/index';
import User from 'components/user/index';
import Vote from 'components/vote/index';

import * as models from 'models/index';

import { Connect } from 'store/index';

import api from 'util/api';
import * as cmsHelpers from 'util/cms-helpers';
import * as constants from 'util/constants';
import * as fbHelpers from 'util/fb_helpers';
import * as googleHelpers from 'util/google-helpers';
import { loginWithJwt } from 'util/twitter';
import { checkIfTrue, insertFonts, normalizeForUrl } from 'util/helpers';
import history from 'util/history';
import { storageFactory } from 'util/storage_helpers';

import { style } from './style';

const localStore = storageFactory(localStorage);

class App extends React.Component<models.store.IAppProps> {
  public constructor(props: models.store.IAppProps) {
    super(props);
  }
  timeoutCms: number = 5000;
  timeoutStyles: number = 60000;

  public async componentDidMount(): Promise<any> {

    const { sid = '', wid = api.defaults.wid } = api.storage.qsps;

    const cmsData = await api.fetchCms(wid, sid);

    if (!cmsData) { return null; }

    if (cmsData.text.login.settings.display_facebook) {
      fbHelpers.initializeFbSdk(cmsData.social.fb.id);
    }

    this.props.cmsFn.storeCmsData(cmsData);
    const geoData = await cmsHelpers.checkGeo(cmsData);

    await this.props.cmsFn.handleGeoData(geoData);

    // Fetch supporting widgets
    const { snapshot_settings } = this.props.cmsData.text;
    const { copy_sid: copySid = '', copy_wid: copyWid = snapshot_settings.copy_wid,
            contestants_sid: contestantsSid = '', contestants_wid: contestantsWid = snapshot_settings.contestants_wid,
            styles_sid: stylesSid = '', styles_wid: stylesWid = snapshot_settings.styles_wid
    } = api.storage.qsps;

    const copyData = await api.fetchCms(copyWid, copySid);
    const contestantsData = await api.fetchCms(contestantsWid, contestantsSid);
    const stylesData = await api.fetchCms(stylesWid, stylesSid);

    if (!copyData || !contestantsData || !stylesData) { return null; }

    await this.props.cmsFn.storeData({ copyData, contestantsData: contestantsData, stylesData: stylesData.text });

    if (this.props.cmsProps.isAppReady && this.props.cmsData.text && this.props.cmsProps.inRegion) {
      const trackingNameSpace = this.props.cmsData.name;
      googleHelpers.initializeGoogleAnalytics(this.props.cmsData.settings.google_analytics, { namespace: trackingNameSpace });
      googleHelpers.addLinkClickListener();

      insertFonts(this.props.stylesData.global.font.fontImport);
      this._pollCms();
      this._pollStyles();
    }

    this.props.globalFn.setPym();

    this._readLocalStore();
  }

  componentWillUnmount(): void {
    clearTimeout(this.timeoutCms);
    clearTimeout(this.timeoutStyles);
    googleHelpers.removeLinkClickListener();
  }
  async componentDidUpdate(prevProps: models.store.IAppProps): Promise<any> {
    if (prevProps.cmsData.name !== this.props.cmsData.name) {
      googleHelpers.updateNameSpace(this.props.cmsData.name);
    }
  }

  render(): React.ReactNode {
    if (!this.props.cmsProps.isAppReady || !this.props.cmsData.text) {
      return <Loading />;
    }

    const { text, settings } = this.props.cmsData;
    const copy = this.props.copyData.text;
    const { stylesData } = this.props;
    const styles = style({
      pageStyles: this.props.stylesData.app,
      globalStyles: this.props.stylesData.global,
      modalOpen: this.props.modalProps.type !== ""
    });
    const gridEnabled = (checkIfTrue(settings.window_status) || checkIfTrue(text.closed.settings.display_grid));

    const ComposedLogin = (
      <Login SwapLoading={Loading}
        SwapError={ErrorFbPermissions}>
        <Terms />
      </Login>
    );

    const modalMap: {[key: string]: any} = {
      confirmation: (
        <Confirmation>
          <Share />
        </Confirmation>),
      errorGeneric: <ErrorMessage data={copy.errors.generic} styles={stylesData.errors.generic}/>,
      errorOverlimit: <ErrorMessage data={copy.errors.overlimit} styles={stylesData.errors.overlimit}/>,
      errorWindow: <ErrorMessage data={copy.errors.window} styles={stylesData.errors.window}/>,
      login: ComposedLogin,
      vote: <Vote SwapLogin={
        <Login SwapLoading={Loading}
          SwapError={ErrorFbPermissions}
          isNested={true}>
          <Terms />
        </Login>
      } />
    };

    return (
      <div className={css(styles.page)}>
        <div className={css(styles.app_container)} aria-hidden={this.props.modalProps.type !== ""}>

          {checkIfTrue(text.ads.leaderboard.settings.display) &&
            <AdUnit size={constants.AD_UNITS.LEADERBOARD} /> }

          {checkIfTrue(text.ads.mobile_leaderboard.settings.display) &&
            <AdUnit size={constants.AD_UNITS.MOBILE_LEADERBOARD} />}

          {checkIfTrue(text.header.settings.display) &&
            <Header>

              {checkIfTrue(text.ads.rectangle.settings.display) &&
              <AdUnit  size={constants.AD_UNITS.RECTANGLE} />}
              {checkIfTrue(text.ads.mobile_rectangle.settings.display) &&
                <AdUnit size={constants.AD_UNITS.MOBILE_RECTANGLE} />}

            </Header> }

          { this.props.cmsProps.inRegion?
          <main className={css(styles.app)} role='main'>

            { !checkIfTrue(settings.window_status) && !gridEnabled &&
              <Closed>
                {checkIfTrue(text.closed.settings.display_countdown) &&
                  <Countdown key='countdown' />}
              </Closed> }

            <Switch>
              <Route exact={true} path='/'>
                { checkIfTrue(settings.window_status) &&
                  <User /> }

                { gridEnabled && this.props.isCategoryVote &&
                  <Categories /> }

                { gridEnabled && !this.props.isCategoryVote &&
                  <Redirect to={{
                    pathname: `/${normalizeForUrl(this.props.cmsData.data[0].name)}`,
                    search: history.location.search}}
                  /> }
              </Route>
              <Route path={["/:name/:detail", "/:name"]} render={(props) => {
                if (!gridEnabled) { return null; }

                if (!this.props.isCategoryVote && !props.match.params['detail']) {
                  const categoryName = normalizeForUrl(this.props.cmsData.data[0].name);
                  const displayContestant = categoryName !== props.match.params.name;

                  if (displayContestant) {
                    return <Redirect to={{
                      pathname: `/${categoryName}/${props.match.params.name}`,
                      search: history.location.search}}
                    />
                  }
                }

                return (
                  <>
                    { this.props.isCategoryVote && checkIfTrue(text.grid.settings.display_navigation) &&
                      <Navigation /> }

                    { checkIfTrue(settings.window_status) &&
                      <User /> }

                    <Grid>
                      { checkIfTrue(text.ads.square.settings.display) &&
                      <AdUnit size={constants.AD_UNITS.SQUARE} /> }

                    </Grid>

                    <AllCatButton />
                  </>
                )
              }} />
            </Switch>

            { checkIfTrue(text.ads.leaderboard_bottom.settings.display) &&
              <AdUnit size={constants.AD_UNITS.LEADERBOARD_BOTTOM} /> }

            { checkIfTrue(text.ads.mobile_leaderboard_bottom.settings.display) &&
              <AdUnit size={constants.AD_UNITS.MOBILE_LEADERBOARD_BOTTOM} /> }
          </main> :
          <ErrorMessage data={text.errors.geo} styles={stylesData.errors.geo} track={constants.GA_PAGES.GEO}/> }

          { checkIfTrue(text.footer.settings.display) &&
            <Footer/> }
        </div>

        {modalMap[this.props.modalProps.type] && (
            <Modal>
              {modalMap[this.props.modalProps.type]}
              {checkIfTrue(text.ads.modal.settings.display) &&
              this.props.modalProps.type !== 'login' &&
              <AdUnit size={constants.AD_UNITS.MODAL} />}
            </Modal>)}

      </div>
    );
  }

  async _pollCms() {
    const { sid = '', wid = api.defaults.wid } = api.storage.qsps;
    const cmsData = await api.fetchCms(wid, sid);

    if (cmsData) {
      await this.props.cmsFn.storeCmsData(cmsData);
    }

    // Fetch supporting widgets
    const { snapshot_settings } = this.props.cmsData.text;
    const { copy_sid: copySid = '', copy_wid: copyWid = snapshot_settings.copy_wid,
            contestants_sid: contestantsSid = '', contestants_wid: contestantsWid = snapshot_settings.contestants_wid
    } = api.storage.qsps;

    const copyData = await api.fetchCms(copyWid, copySid);

    if (copyData) {
      await this.props.cmsFn.storeData({ copyData });
    }

    const contestantsData = await api.fetchCms(contestantsWid, contestantsSid);
    if (contestantsData) {
      await this.props.cmsFn.storeData({ contestantsData });
    }

    let pollingRate = constants.MIN_POLLING_RATE;
    if (this.props.cmsData && (this.props.cmsData.text.snapshot_settings.polling_rate >= constants.MIN_POLLING_RATE)) {
      pollingRate = this.props.cmsData.text.snapshot_settings.polling_rate;
    }

    this.timeoutCms = window.setTimeout(this._pollCms.bind(this), pollingRate)
  }

  async _pollStyles() {
    const { snapshot_settings } = this.props.cmsData.text;
    const { styles_sid: stylesSid = '', styles_wid: stylesWid = snapshot_settings.styles_wid
    } = api.storage.qsps;
    const stylesData = await api.fetchCms(stylesWid, stylesSid);

    if (stylesData) {
      await this.props.cmsFn.storeData({ stylesData });
    }

    let pollingRate = constants.MIN_POLLING_RATE;
    if (this.props.cmsData && (this.props.stylesData.snapshotSettings.pollingRate >= constants.MIN_POLLING_RATE)) {
      pollingRate = this.props.stylesData.snapshotSettings.pollingRate;
    }
    this.timeoutStyles = window.setTimeout(this._pollStyles.bind(this), pollingRate)
  }

  _readLocalStore = async () => {
    if (localStore.getItem(constants.EMAIL_LOCALSTORAGE_LABEL)) {
      const payload = JSON.parse(localStore.getItem(constants.EMAIL_LOCALSTORAGE_LABEL));
      const email = Object.keys(payload)[0];
      this.props.termsFn.updateTerms(payload[email]);
      this.props.authFn.loginViaEmail(email);
    }
    else if (localStore.getItem(constants.TWITTER_LOCALSTORAGE_LABEL)) {
      const payload = JSON.parse(localStore.getItem(constants.TWITTER_LOCALSTORAGE_LABEL));

      const jwt = Object.keys(payload)[0];
      const { twitter_auth_key, twitter_auth_url } = this.props.cmsData.settings;

      let twitterPayload = {} as unknown as models.auth.ITwitterLoginPayload;

      try {
        twitterPayload = await loginWithJwt(jwt, twitter_auth_key, twitter_auth_url) as models.auth.ITwitterLoginPayload;
      } catch(e) {
        localStore.removeItem(constants.TWITTER_LOCALSTORAGE_LABEL);
        return;
      }

      await this.props.authFn.loginViaTwitter(twitterPayload);
    }
    else {
      fbHelpers.checkLoginState()
        .then((response) => {
          const payload = JSON.parse(localStore.getItem(constants.FACEBOOK_LOCALSTORAGE_LABEL));
          const email = Object.keys(payload)[0];
          this.props.termsFn.updateTerms(payload[email]);
          return this.props.authFn.loginViaFacebook(response)
        })
        .catch(() => {
          //Do nothing
        });
    }
  };
}

export default withRouter(Connect(App));
