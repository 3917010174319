import * as React from 'react';

export const CloseIcon = () => {
  return (
    <span className='close-icon'>
      <svg width='16.97' height='16.97' viewBox='0 0 16.97 16.97'>
        <path
          d='M12.5,9.67L18.16,4,21,6.84,15.33,12.5,21,18.16,18.16,21,12.5,15.33,6.84,21,4,18.16,9.67,12.5,4,6.84,6.84,4Z'
          transform='translate(-4.01 -4.01)'
        />
      </svg>
    </span>
  );
};

export const RightArrowIcon = () => {
  return (
    <i className='right-arrow-icon'>
      <svg width='28.99' height='49.5' viewBox='0 0 28.99 49.5'>
        <path
          d='M23.85,27.92L3.34,7.41,7.58,3.17,28.09,23.67l4.24,4.24L7.58,52.66,3.34,48.42Z'
          transform='translate(-3.34 -3.17)'
        />
      </svg>
    </i>
  );
};

export const LeftArrowIcon = () => {
  return (
    <i className='left-arrow-icon'>
      <svg width='28.99' height='49.5' viewBox='0 0 28.99 49.5'>
        <path
          d='M11.83,27.92L32.33,48.42l-4.24,4.24L7.58,32.16,3.34,27.92,28.09,3.17l4.24,4.24Z'
          transform='translate(-3.34 -3.17)'
        />
      </svg>
    </i>
  );
};

export const DownArrowIcon = () => {
  return (
    <i className='down-arrow-icon'>
      <svg xmlns="http://www.w3.org/2000/svg" width="90.42" height="51.54" viewBox="0 0 90.42 51.54">
        <path
          d="M50.55,78.85A6.31,6.31,0,0,1,46.07,77L7.19,38.12a6.33,6.33,0,0,1,9-9l34.4,34.4L85,29.17a6.33,6.33,0,1,1,9,9L55,77a6.31,6.31,0,0,1-4.48,1.85h0Z"
          transform="translate(-5.34 -27.31)"/>
      </svg>
    </i>
  );
};

// Social
export const FacebookIcon = () => {
  return (
    <span className='facebook-icon'>
      <svg x="0px" y="0px" viewBox="0 0 28 28">
      <g>
        <g transform="translate(-171.000000, -425.000000)">
          <g transform="translate(0.000000, 0.000000)">
            <g transform="translate(122.000000, 425.000000)">
              <g transform="translate(49.000000, 0.000000)">
                <path d="M14,0C6.3,0,0,6.3,0,14c0,6.4,4.3,11.8,10.1,13.4v-10H7.8v-3.7h2.3v-2.2V9.9c0,0-0.1-3,3.9-3.9
                  c2.7-0.6,5.4,0.3,5.4,0.3l-0.5,3.8c0,0-4-1.5-4,1.5v2.2h3.9l-0.3,3.7h-3.5V28C22.2,27.6,28,21.4,28,14C28,6.3,21.7,0,14,0"/>
              </g>
            </g>
          </g>
        </g>
      </g>
      </svg>
    </span>
  )
};

export const TwitterIcon = () => {
  return (
    <span className='twitter-icon'>
      <svg width="47.33" height="38.46" viewBox="0 0 47.33 38.46">
        <path d="M16.22,46.25c17.86,0,27.63-14.8,27.63-27.63,0-.42,0-0.84,0-1.26a19.76,19.76,0,0,0,4.85-5,19.38,19.38,0,0,1-5.58,1.53,9.74,9.74,0,0,0,4.27-5.37,19.46,19.46,0,0,1-6.17,2.36,9.72,9.72,0,0,0-16.55,8.86,27.57,27.57,0,0,1-20-10.15,9.72,9.72,0,0,0,3,13,9.64,9.64,0,0,1-4.41-1.22v0.12A9.71,9.71,0,0,0,11,31a9.7,9.7,0,0,1-4.38.17,9.72,9.72,0,0,0,9.07,6.74A19.49,19.49,0,0,1,3.65,42a19.77,19.77,0,0,1-2.31-.14,27.49,27.49,0,0,0,14.89,4.35" transform="translate(-1.33 -7.79)"/>
      </svg>
    </span>
  )
}
