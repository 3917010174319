import * as React from 'react';
import { css } from 'aphrodite/no-important';
import * as models from 'models/index';
import { Connect } from 'store/index';
import * as helpers from 'util/helpers';
import { style } from './style';

class AdUnit extends React.Component<models.store.IAppProps & models.ads.IAdUnitData> {
  public render(): React.ReactNode {

    const desktopColumnData = this.props.cmsData.text.grid.settings.columns.desktop;
    const tabletColumnData = this.props.cmsData.text.grid.settings.columns.tablet;

    const { copy, unit } = this.props.cmsData.text.ads[`${this.props.size}`];
    const styles = style({
      desktopColumn: desktopColumnData > 3 ? desktopColumnData - 2 : desktopColumnData - 1,
      tabletColumn: tabletColumnData > 3 ? 2 : 1,
      adUnitStyles: helpers.normalizeStyles(this.props.cmsData.text.ads[`${this.props.size}`].styles),
      size: this.props.size
    });

    return (
      <section className={css(styles.ads, styles[`${this.props.size}_wrapper`])}>

        {copy.sponsored_by &&
          <label className={css(styles.sponsored_by)}>
            {copy.sponsored_by}
          </label> }

        <a className={css(styles[this.props.size], styles.adLink)}
           href={unit.link}
           target='_blank'
           rel='noopener noreferrer' >
          <img src={unit.image} alt={copy.alt_text} />
        </a>
      </section>
    );
  }
}

export default Connect(AdUnit);
