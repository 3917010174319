import * as models from 'models/index';
import { media } from 'util/style-variables';
import { StyleSheet } from 'aphrodite/no-important';

export const style: any = (styles: models.base.IGenericObject) =>
  StyleSheet.create({
    error: {
      flexGrow: 1,
      ...styles.globalStyles.container,
      padding: '25px 20px',
      textAlign: 'center',
      backgroundColor: '#fff'
    },

    headline: {
      fontSize: '24px',
      paddingBottom: '5px',
      ...styles.globalStyles.headlines,
      ...styles.errorStyles.headline,

      [media.tablet]: {
        ...styles.globalStyles.headlines.tablet
      },

      [media.desktop]: {
        ...styles.globalStyles.headlines.desktop
      }
    },

    description: {
      fontSize: '18px',
      ...styles.errorStyles.description
    },

    link: {
      ...styles.errorStyles.link
    }
  });
