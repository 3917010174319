import * as models from 'models/index';
import { AUTH_METHODS, ACTION_TYPES } from 'util/constants';
import { AppStateProvider } from 'store/index';
import api from 'util/api';

export function loginViaEmail(this: AppStateProvider, email: string): Promise<any> {
  const newUser = {
    email,
    isAuthorized: true,
    method: AUTH_METHODS.EMAIL
  }

  return new Promise<any>((resolve: (value: any) => void | undefined) => {
  this.setState((prevState: models.store.IAppProps) => {
    return {
      ...prevState,
      globalProps: {
        ...this.state.globalProps,
          userData: {
            ...this.state.globalProps.userData,
            ...newUser
          }
        }
      };
    }, () => resolve(this.state));
  });
}

export function loginViaFacebook(this: AppStateProvider, payload: models.auth.IFacebookLoginPayload): void {
  const newUser = {
    email: payload.email,
    facebookId: payload.id,
    isAuthorized: true,
    method: AUTH_METHODS.FACEBOOK,
    name: `${payload.first_name} ${payload.last_name}`,
    fb_email: payload.email,
    fb_first_name: payload.first_name,
    fb_last_name: payload.last_name
  }

  this.setState((prevState: models.store.IAppProps) => {
    return {
      ...prevState,
      globalProps: {
        ...this.state.globalProps,
        userData: {
          ...this.state.globalProps.userData,
          ...newUser
        }
      }
    };
  });
}

export function loginViaTwitter(this: AppStateProvider, payload: models.auth.ITwitterLoginPayload): Promise<any> {
  const { userData } = this.state.globalProps;

  const newUser = {
    email: payload.email || payload.id,
    isAuthorized: true,
    method: AUTH_METHODS.TWITTER,
    jwt: payload.token,
    name: payload.displayName,
    twitter_handle: payload.userName,
    twitter_id: payload.id
  }

  return new Promise<any>((resolve: (value: any) => void | undefined) => {
  this.setState((prevState: models.store.IAppProps) => {
    return {
      ...prevState,
      globalProps: {
        ...this.state.globalProps,
          userData: {
            ...userData,
            ...newUser
          }
        }
      };
    }, () => resolve(this.state));
  });
}

export function logoffEmail(this: AppStateProvider): void {
  const newUser = {
    email: '',
    facebookId: '',
    isAuthorized: false,
    method: '',
    name: ''
  }

  this.setState((prevState: models.store.IAppProps) => {
    return {
      ...prevState,
      globalProps: {
        ...this.state.globalProps,
        userData: {
          ...this.state.globalProps.userData,
          ...newUser
        }
      }
    };
  });
}

export function logoffTwitter(this: AppStateProvider): void {
  const { userData } = this.state.globalProps;

  const newUser = {
    email: '',
    isAuthorized: false,
    method: '',
    jwt: '',
    name: '',
    twitter_handle: '',
    twitter_id: ''
  }

  this.setState((prevState: models.store.IAppProps) => {
    return {
      ...prevState,
      globalProps: {
        ...this.state.globalProps,
        userData: {
          ...userData,
          ...newUser
        }
      }
    };
  });
}


export function logoffFacebook(this: AppStateProvider): void {
  const newUser = {
    email: '',
    facebookId: '',
    isAuthorized: false,
    method: '',
    name: '',
    fb_email: '',
    fb_first_name: '',
    fb_last_name: ''
  }

  this.setState((prevState: models.store.IAppProps) => {
    return {
      ...prevState,
      globalProps: {
        ...this.state.globalProps,
        userData: {
          ...newUser
        }
      }
    };
  });
}

export function submitLogin(this: AppStateProvider): void {
  const userPayload = this.state.apiFn.getUserPayload();

  const payload = {
    action_type: ACTION_TYPES.LOGIN,
    apiKey: this.state.cmsData.settings.apiKey,
    timestamp: new Date().getTime(),
    ...userPayload
  };

 return api.postToVoteApi(payload, this.state.cmsData.settings.version_id);
}
