import * as ReactGA from 'react-ga';
import * as constants from 'util/constants';
import { deleteCookie } from 'util/helpers';

let _isGoogleInitialized = false;
let _isTargetingAllowed = false;
let _namespace = '';

interface GoogleAnalyticsInitOptions {
  namespace: string;
}
interface IGooglePageView {
  type: string;
  payload: string;
}
interface IGoogleEvent {
  type: string;
  payload: { category: string; action: string; label: string };
}

interface IGoogleModalView {
  type: string;
  payload: string;
}

let googleQueue: Array<IGoogleEvent | IGooglePageView | IGoogleModalView> = [];

const GA_LOCAL_STORAGE_KEY = 'ga:clientId';

export const initializeGoogleAnalytics = (gaId: string, options: GoogleAnalyticsInitOptions) => {

  _isTargetingAllowed = true;
  _namespace = options.namespace;

  const gaOptions: ReactGA.GaOptions = {
    storage: 'none',
    clientId: localStorage.getItem(GA_LOCAL_STORAGE_KEY) || undefined,
  };
  const initOptions: ReactGA.InitializeOptions = {
    gaOptions: gaOptions,
  };

  ReactGA.initialize(gaId, initOptions);

  _isGoogleInitialized = true;

  if (googleQueue.length > 0) {
    googleQueue.forEach((call: IGooglePageView | IGoogleEvent) => {
      switch (call.type) {
        case 'page':
          trackGooglePage((call as IGooglePageView).payload);
          break;
        case 'event':
          const { category, action, label } = (call as IGoogleEvent).payload;
          trackGoogleEvent(category, action, label);
          break;
        case 'modal':
          trackGoogleModal((call as IGoogleModalView).payload);
      }
    });

    googleQueue = [];
  }
};

export const isInitialized = () => {
  return _isGoogleInitialized;
};

export const trackGoogleEvent = (
  category: string,
  action: string,
  label: string = ''
) => {

  if (!_isTargetingAllowed) return;

  if (_isGoogleInitialized) {
    ReactGA.event({ category, action: `${category}: ${_namespace}: ${action}`, label });
  } else {
    googleQueue.push({
      payload: { category, action, label },
      type: 'event',
    });
  }
};

export const trackGooglePage = (page: string) => {
  if (!_isTargetingAllowed) return;

  if (_isGoogleInitialized) {
    ReactGA.pageview(`${_namespace}: ${page}`);
  } else {
    googleQueue.push({
      payload: page,
      type: 'page',
    });
  }
};

export const trackGoogleModal = (modal: string) => {
  if (!_isTargetingAllowed) return;

  if (_isGoogleInitialized) {
    ReactGA.modalview(modal);
  } else {
    googleQueue.push({
      payload: modal,
      type: 'modal',
    });
  }
};

export const addLinkClickListener = () => {
  document.addEventListener('click', checkLinkClick, false);
};

export const removeLinkClickListener = () => {
  document.removeEventListener('click', checkLinkClick, false);
};

export const checkLinkClick = (evt: any) => {
  if (!_isTargetingAllowed) return;

  if(evt.target.tagName.toLowerCase() === 'a') {
      const category = constants.GA_CATEGORIES.LINK_CLICK;
      const action = evt.target.href;
      const label = evt.target.textContent;
      trackGoogleEvent(category,action,label);
  }
}

export const updateNameSpace = (newNamespace: string) => {
  _namespace = newNamespace;
}
