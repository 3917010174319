import * as React from 'react';
import { css } from 'aphrodite/no-important';
import * as models from 'models/index';
import { Connect } from 'store/index';
import * as constants from 'util/constants';
import * as fbHelpers from 'util/fb_helpers';
import * as googleHelpers from 'util/google-helpers';
import { storageFactory } from 'util/storage_helpers';
import { style } from './style';

const localStore = storageFactory(localStorage);

class User extends React.Component<models.store.IAppProps> {
  public state = ({
    triggeredLogin: false,
    displayLogoutConfirmation: false
  });

  public componentDidUpdate(prevProps: models.store.IAppProps): void {
    const { isAuthorized } = this.props.globalProps.userData;
    const wasAuthorized = prevProps.globalProps.userData.isAuthorized;

    if (this.state.triggeredLogin && this.props.globalProps.userData.isAuthorized) {
      this.props.modalFn.closeModal();
    }

    if (this.state.triggeredLogin && !this.props.modalProps.type) {
      this.setState({ triggeredLogin: false });
    }

    if (!isAuthorized && wasAuthorized) {
      this._handleLogoutMessage();
    }
  }

  public render(): React.ReactNode {
    const copy = { ...this.props.copyData.text.user };
    const { email, isAuthorized, name } = this.props.globalProps.userData;
    const styles = style({
      globalStyles: this.props.stylesData.global,
      userStyles: this.props.stylesData.user
    });

    let content = (
      <button
        className={css(styles.button)}
        onClick={this._handleLogon}>
        {copy.login_cta}
      </button>
    );

    if (isAuthorized) {
      const userId = `<span>${name || email}</span>`;
      const greetingMessage = copy.greeting_message.replace('{{USER}}', userId);

      content = (
        <div>
          { copy.greeting_message &&
            <span className={css(styles.greeting_message)} dangerouslySetInnerHTML={{ __html: greetingMessage }} />  }
          <button
            className={css(styles.button)}
            onClick={this._handleLogoff}
          >
            {copy.logout_cta}
          </button>
        </div>
      );
    }

    return (
      <div className={css(styles.user)}>
        <p className="screen-reader-text" aria-live="assertive">
          { this.state.displayLogoutConfirmation? copy.logout_confirmation : '' }
        </p>
        {content}
      </div>
    );
  }

  private _handleLogoff = () => {
    if (this.props.globalProps.userData.method === constants.AUTH_METHODS.EMAIL) {
      localStore.removeItem(constants.EMAIL_LOCALSTORAGE_LABEL);
      googleHelpers.trackGoogleEvent(constants.GA_CATEGORIES.BUTTON_CLICK, constants.GA_EVENTS.LOGOUT, '');
      return this.props.authFn.logoffEmail();
    }

    if (this.props.globalProps.userData.method === constants.AUTH_METHODS.TWITTER) {
      localStore.removeItem(constants.TWITTER_LOCALSTORAGE_LABEL);
      googleHelpers.trackGoogleEvent(constants.GA_CATEGORIES.BUTTON_CLICK, constants.GA_EVENTS.LOGOUT, '');
      return this.props.authFn.logoffTwitter();
    }

    if (this.props.globalProps.userData.method === constants.AUTH_METHODS.FACEBOOK) {
      localStore.removeItem(constants.FACEBOOK_LOCALSTORAGE_LABEL);
      googleHelpers.trackGoogleEvent(constants.GA_CATEGORIES.BUTTON_CLICK, constants.GA_EVENTS.LOGOUT, '');
      return this._handleFacebookLogoff();
    }
  };

  private _handleLogon = () => {
    this.props.modalFn.openModal(constants.MODAL_TYPES.login);
    this.setState({ triggeredLogin: true, displayLogoutConfirmation: false });
  };

  private _handleFacebookLogoff = () => {
    window.FB.getLoginStatus((response: any) => {
      if (response.status !== 'connected') {
        return fbHelpers.checkLogoutState()
          .then(this.props.authFn.logoffFacebook);
      }

      return window.FB.logout(() => {
        return fbHelpers.checkLogoutState()
          .then(this.props.authFn.logoffFacebook);
      });
    }, true);
  };

  private _handleLogoutMessage = () => {
    this.setState({ displayLogoutConfirmation: true });

    setTimeout(() => {
      this.setState({ displayLogoutConfirmation: false });
    }, 1000);
  }
}

export default Connect(User);
