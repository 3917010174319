import axios, { AxiosResponse } from 'axios';
import { axiosETAGCache } from 'axios-etag-cache';
import * as models from 'models/index';
import * as constants from 'util/constants';
import * as helpers from 'util/helpers';

class Api {
  storage = this.initializeStorage();
  defaults: models.api.IDefaults = { wid: constants.DEFAULT_WID };

  constructor() {
    this.getQSPs();
  }

  fetchCms(wid: string, sid: string): Promise<models.api.ICmsData> | any {
    const sidString = sid ? `/snapshot/${sid}` : '';

    return axiosETAGCache(axios as any)
      .get(`${constants.API_CMS}${wid}${sidString}`)
      .then((res: AxiosResponse) => {
        if (!('etag' in res.headers)) { return null; }
        const { data, name } = res.data.snapshot;
        return { ...data, name }
      })
      .catch(err => {
        return null;
      });
  }

  fetchGeo(): Promise<models.api.IGeoData> {
    return axios
      .get(constants.API_GEO)
      .then((res: AxiosResponse) => res.data)
      .catch(err => {
        throw new Error(err);
      });
  }

  private getQSPs(): void {
    window.location.search
      .slice(1)
      .split('&')
      .forEach(item => {
        const [key, value = ''] = item.split('=');

        this.storage.qsps[key] = value;
      });
  }
  /**
   * initializeStorage
   */
  private initializeStorage(): models.api.IStorage {
    const storage = Object.create(null);
    storage.cms = Object.create(null);
    storage.qsps = Object.create(null);

    return storage;
  }

  postToVoteApi(payload: any, versionId: string): any {
    const qsp = helpers.convertToQspString(payload);
    const hash = helpers.createHash(qsp, `${versionId}${constants.VERSION_CHECK}`);
    const encodedHash = helpers.fixedEncodeUriComponent(hash);
    const url = `${constants.CONNECT_ENDPOINT}?${qsp}`;
    const data = `Authorization=${encodedHash}`;
    const options = {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
    };

    return axios.post(url, data, options)
      .then((res: AxiosResponse) => res.data)
      .catch(err => console.error('Voteapi call failed:', err.message));
  }
}

export default new Api();
