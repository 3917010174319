import * as models from 'models/index';
import { media } from 'util/style-variables';
import { StyleSheet } from 'aphrodite/no-important';

export const style: any = (styles: models.base.IGenericObject) => StyleSheet.create({
  grid: {
    padding: '20px 0',
    width: '100%',
    ...styles.globalStyles.container,
    ...styles.gridStyles.general,

    [media.tablet]: {
      ...styles.globalStyles.container.tablet,
      ...styles.gridStyles.general.tablet,
    },

    [media.desktop]: {
      ...styles.globalStyles.container.desktop,
      ...styles.gridStyles.general.desktop,
    }
  },

  text_wrapper: {
    paddingBottom: '10px',

    [media.tablet]: {
      paddingBottom: '0'
    }
  },

  headline: {
    paddingBottom: '0px',
    lineHeight: 1.2,
    ...styles.globalStyles.headlines,
    ...styles.gridStyles.headline,

    [media.tablet]: {
      ...styles.globalStyles.headlines.tablet,
      ...styles.gridStyles.headline.tablet
    },

    [media.desktop]: {
      ...styles.globalStyles.headlines.desktop,
      ...styles.gridStyles.headline.desktop
    }
  },

  subheadline_wrapper: {
    display: 'flex',
    flexDirection: 'column',

    [media.tablet]: {
      flexDirection: 'row'
    }
  },

  description: {
    flexGrow: 1,
    ...styles.gridStyles.description,

    [media.tablet]: {
      marginBottom: 15,
      ...styles.gridStyles.description.tablet
    },

    [media.desktop]: {
      ...styles.gridStyles.description.desktop
    }
  },

  additional_info: {
    fontSize: '14px',
    padding: '10px 0 0',
    ...styles.gridStyles.additionalInfo,

    [media.tablet]: {
      fontSize: '16px',
      ...styles.gridStyles.additionalInfo.tablet
    },

    [media.desktop]: {
      ...styles.gridStyles.additionalInfo.desktop
    }
  },

  count: {
    ...styles.gridStyles.voteCountNumber,

    [media.tablet]: {
      ...styles.gridStyles.voteCountNumber.tablet
    },

    [media.desktop]: {
      ...styles.gridStyles.voteCountNumber.desktop
    }
  },

  countLabel: {
    padding: 0,
    ...styles.gridStyles.voteCountLabel,

    [media.tablet]: {
      textAlign: 'right',
      ...styles.gridStyles.voteCountLabel.tablet
    },

    [media.desktop]: {
      ...styles.gridStyles.voteCountLabel.desktop
    }
  },

  panel_window: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    width: 'auto',
    minWidth: '100%',
    margin: '0 -15px',
  },

  sort_wrapper: {
    paddingTop: '10px',

    [media.tablet]: {
      paddingTop: '0'
    }
  },

  sort_button_inactive: {
    backgroundColor: 'transparent',
    display: 'inline-block',
    color: '#999',
    cursor: 'pointer',
    fontSize: '16px'
  },

  sort_button_active: {
    backgroundColor: 'transparent',
    display: 'inline-block',
    pointerEvents: 'none',
    cursor: 'default',
    color: '#000',
    fontSize: '16px'
  },

  sort_label: {
    color: '#999',
    fontSize: '16px'
  }
});
