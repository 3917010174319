import * as models from 'models/index';
import { media, children } from 'util/style-variables';
import { StyleSheet } from 'aphrodite/no-important';

export const style: any = (styles: models.base.IGenericObject) => StyleSheet.create({
  navigation: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 15,
    ...styles.navigationStyles.general,

    [media.tablet]: {

      ...styles.navigationStyles.general.tablet
    },

    [media.desktop]: {
      ...styles.navigationStyles.general.desktop
    },


    [children('a')]: {
      textDecoration: 'none',
      padding: 0,
      margin: 0,
      transform: 'translateY(2px)'
    },
  },

  button: {
    margin: 0,
    fontSize: 14,
    ...styles.navigationStyles.links,

    [media.tablet]: {
      ...styles.navigationStyles.links.tablet
    },

    [media.desktop]: {
      ...styles.navigationStyles.links.desktop
    },

    [children('svg')]: {
      height: 15,
      width: 'auto',
      transform: 'translateY(2px)',
      ...styles.navigationStyles.icons,

      [media.tablet]: {

        ...styles.navigationStyles.icons.tablet
      },

      [media.desktop]: {
        ...styles.navigationStyles.icons.desktop
      }
    },

    [children('i')]: {
      padding: 0
    }
  },

  allCatButton: {
    marginBottom: 25,
    textDecoration: 'underline',
  },

  carouselButton: {
    display: 'none',

    [media.tablet]: {
      display: 'inline-block',
      padding:  '0 7px',
    },
  },

  centerText: {
    textAlign: 'center',
    color: styles.navigationStyles.general.centerTextColor
  },
});
