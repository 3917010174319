import * as React from 'react';
import { css } from 'aphrodite/no-important';
import * as models from 'models/index';
import { Connect } from 'store/index';
import * as constants from 'util/constants';
import * as googleHelpers from 'util/google-helpers';
import * as helpers from 'util/helpers';
import * as fbHelpers from 'util/fb_helpers';
import { FacebookIcon, TwitterIcon } from 'util/icons';
import { style } from './style';

class Share extends React.Component<models.store.IAppProps> {
  public render(): React.ReactNode {
    const { settings } = this.props.cmsData.text.share;
    const copy = this.props.copyData.text.share;
    const { contestantId } = this.props.globalProps;
    const contestant = this.props.gridFn.getContestantById(contestantId);
    const styles = style({
      shareStyles: this.props.stylesData.thankYou.share
    });

    const tweet = copy.twitter.copy
      .replace(/{{NAME}}/gi, contestant.nickname ? contestant.nickname.trim() : contestant.name.trim())
      .replace(/{{CATEGORY}}/gi, this.props.globalProps.category.name.trim())
      .replace(/{{HASHTAG}}/gi, contestant.hashtag.trim());

    return (
      <div className={css(styles.share)}>
        {(helpers.checkIfTrue(settings.display_twitter) ||
          helpers.checkIfTrue(settings.display_facebook)) &&
          <h3 className={css(styles.cta)}>{copy.cta}</h3>
        }

        {helpers.checkIfTrue(settings.display_twitter) &&
          <button
            className={`${css(styles.social_button)} ${css(styles.social_twitter)}`}
            onClick={() => helpers.twitterShare(tweet, constants.GA_EVENTS.TWITTER_SHARE, copy.twitter.link)}
            aria-label={copy.twitter.icon_accessibility}>
            <TwitterIcon />
          </button>
        }

        {helpers.checkIfTrue(settings.display_facebook) &&
          <button
            className={`${css(styles.social_button)} ${css(styles.social_facebook)}`}
            onClick={() => this._facebookShare()}
            aria-label={copy.facebook.icon_accessibility}>
            <FacebookIcon />
          </button>
        }
      </div>
    );
  }

  _facebookShare() {
    googleHelpers.trackGoogleEvent(constants.GA_CATEGORIES.BUTTON_CLICK, constants.GA_EVENTS.FACEBOOK_SHARE,'');

    const { copy, url = window.location.href } = this.props.copyData.text.share.facebook;
    const { settings } = this.props.cmsData.text.share;

    if (helpers.checkIfTrue(settings.share_contestant) && this.props.isCategoryVote) {
      const u = encodeURIComponent(url);
      const quote = copy ? `&quote=${encodeURIComponent(copy)}` : '';

      window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${u}${quote}`,
          'popup',
          'width=600, height=400, scrollbars=no'
      );
    } else {
      const { contestantId, category } = this.props.globalProps;
      const contestant = this.props.gridFn.getContestantById(contestantId);

      const url = `${window.location.origin}/share`;

      fbHelpers.share(url, {
        category: category.name,
        name: contestant.name.trim(),
        picture: contestant.share_image.trim(),
        wid: this.props.cmsData.text.snapshot_settings.copy_wid
      });
    }
  }
}

export default Connect(Share);
