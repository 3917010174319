import * as React from 'react';
import history from 'util/history';
import { css } from 'aphrodite/no-important';
import Button from 'components/button';
import * as models from 'models/index';
import { Connect } from 'store/index';
import * as constants from 'util/constants';
import * as helpers from 'util/helpers';
import { style } from './style';

class Confirmation extends React.Component<models.store.IAppProps> {
  _handleNextCategory = () =>{
    const categories = this.props.cmsData.data;
    const length = categories.length;
    const currentCategory = this.props.globalProps.category;

    let index = this.props.cmsData.data.findIndex((c: any) => currentCategory.id === c.id);
    index = (index + 1 + length) % length;

    const nextCategory = this.props.cmsData.data[index];
    const nextCategoryName = helpers.normalizeForUrl(nextCategory.name);

    this.props.modalFn.closeModal();

    history.push({
      pathname: `/${nextCategoryName}`,
      search: history.location.search,
    });

  }

  public render(): React.ReactNode {
    const { contestantId } = this.props.globalProps;
    const contestant = this.props.gridFn.getContestantById(contestantId);
    const { settings } = this.props.cmsData.text.thank_you;
    const copy = this.props.copyData.text.thank_you;
    const { thankYou, global } = this.props.stylesData;
    const styles = style({
      globalStyles: global,
      confirmationStyles: thankYou
    });
    return (
      <section>
        <div className={css(styles.media_container)}>
          <img src={contestant.image} alt={contestant.name} />
        </div>

        <div className={css(styles.information_container)} aria-live='assertive'>
          <h1 className={css(styles.headline)}> {copy.headline} </h1>

          { copy.description_1 &&
            <p className={css(styles.description_1)}> {copy.description_1} </p> }

          { copy.description_2 &&
            <p className={css(styles.description_2)}> {copy.description_2} </p> }

          { helpers.checkIfTrue(settings.display_twitter_vote) &&
            <div className={css(styles.twitter_options)}>
              <h2 className={css(styles.twitter_headline)}>
                {copy.twitter_vote.headline}
              </h2>

              <p className={css(styles.twitter_description)}>
                {copy.twitter_vote.description}
              </p>

              <Button
                buttonData={copy.buttons.twitter_vote}
                buttonStyles={thankYou.buttons.twitterVote}
                onClick={() => helpers.twitterShare(contestant.twitter_vote, constants.GA_EVENTS.TWITTER_VOTE)}
                options={{ 'pushDown': true, globalStyles: this.props.stylesData.global.buttons }} />
            </div>
          }

          { helpers.checkIfTrue(settings.display_vote_again) &&
            <Button
              buttonData={copy.buttons.vote_again}
              buttonStyles={thankYou.buttons.voteAgain}
              onClick={this.props.modalFn.closeModal}
              options={{ 'pushDown': true, globalStyles: this.props.stylesData.global.buttons }} />
          }

          { helpers.checkIfTrue(settings.display_next_category) &&
            <Button
              buttonData={copy.buttons.next_category}
              buttonStyles={thankYou.buttons.nextCategory}
              onClick={() => this._handleNextCategory()}
              options={{ 'pushDown': true, globalStyles: this.props.stylesData.global.buttons }} />
          }

          { (helpers.checkIfTrue(settings.display_share.display_facebook) ||
          helpers.checkIfTrue(settings.display_share.display_twitter)) &&
            this.props.children
          }

        </div>
      </section>
    );
  }
}

export default Connect(Confirmation);
