export const storageFactory = (storage: any) => {
  let inMemoryStorage: {[key: string]: any} = {};
  const length = 0;

  const isSupported = () => {
    try {
      const testKey = "telescope_tv";
      storage.setItem(testKey, testKey);
      storage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  };

  const clear = () => {
    if (isSupported()) {
      storage.clear();
    } else {
      inMemoryStorage = {};
    }
  };

  const getItem = (name: string) => {
    if (isSupported()) {
      return storage.getItem(name);
    }
    if (inMemoryStorage.hasOwnProperty(name)) {
      return inMemoryStorage[name];
    }
    return null;
  };

  const key = (index: string) => {
    if (isSupported()) {
      return storage.key(index);
    } else {
      return Object.keys(inMemoryStorage)[index] || null;
    }
  };

  const removeItem = (name: string) => {
    if (isSupported()) {
      storage.removeItem(name);
    } else {
      delete inMemoryStorage[name];
    }
  };

  const setItem = (name: string, value: string) => {
    if (isSupported()) {
      storage.setItem(name, value);
    } else {
      inMemoryStorage[name] = String(value);
    }
  };

  return {
    getItem,
    setItem,
    removeItem,
    clear,
    key,
    length,
  };
};
