import * as React from 'react';
import { css } from 'aphrodite/no-important';
import * as models from 'models/index';
import { Connect } from 'store/index';
import * as helpers from 'util/helpers';
import { style } from './style';

class Terms extends React.Component<models.store.IAppProps> {
  UNSAFE_componentWillMount(): void {
    this._storeTermNames();
  }

  render(): React.ReactNode {
    const styles = style({
      termsStyles: this.props.stylesData.login.terms,
      checkedTerms: {},
      settings: {}
    });

    return (
      <div className={css(styles.terms_group)}>
        <ul>
          {this._getOptInsList()
            .map(this._renderOptin)
          }
        </ul>
      </div>
    )
  }

  private _getOptInsList = () => {
    return Object.keys(this.props.cmsData.text.login.terms).filter(category => {
      return category.substring(0, 7) === 'opt_in_';
    })
      .sort();
  };

  private _handleCheckbox = (name: string, isRequired: string) => {
    const { termsProps } = this.props;
    termsProps[name].isChecked = !termsProps[name].isChecked;

    const hasError = helpers.checkIfTrue(isRequired) && !termsProps[name].isChecked;
    termsProps[name].hasError = hasError;

    this.props.termsFn.updateTerms(termsProps);
  };

  private _renderOptin = (optIn: string, i: number) => {
    const { settings } = this.props.cmsData.text.login.terms[optIn];
    const copy = {...this.props.copyData.text.login.terms[optIn]}
    const termsProps = this.props.termsProps[copy.name];
    let hasError = termsProps? termsProps.hasError : false;

    const styles = style({
      termsStyles: this.props.stylesData.login.terms,
      checkedTerms: termsProps ?
        termsProps.isChecked : settings.is_prechecked,
      settings: settings
    });

    if (!copy.copy) {
      return;
    }

    const errorClass = hasError? css(styles.hasError) : 'noerror';

    return (
      <li key={i}>
        <div className={css(styles.term)}>
          <input
            defaultChecked={helpers.checkIfTrue(settings.prechecked)}
            id={copy.name}
            type="checkbox"
            onChange={() => this._handleCheckbox(copy.name, settings.required)} />

          <label
            className={`${css(styles.label, styles.copy)} ${errorClass}` }
            dangerouslySetInnerHTML={{ __html: `${copy.copy}` }}
            htmlFor={copy.name} />
        </div>

        { hasError &&
          <p className={css(styles.error)} role='alert' aria-live='assertive'>{copy.error}</p> }

      </li>
    );
  };

  private _storeTermNames = () => {
    const optInsList = this._getOptInsList()
      .reduce((storage, optInName) => {
        const optInSettings = this.props.cmsData.text.login.terms[optInName].settings;
        const optInCopy = this.props.copyData.text.login.terms[optInName];

        storage[optInCopy.name] = {};
        storage[optInCopy.name].isChecked = helpers.checkIfTrue(optInSettings.prechecked);
        storage[optInCopy.name].isRequired = helpers.checkIfTrue(optInSettings.required);
        return storage;
      }, Object.create(null));

    this.props.termsFn.updateTerms(optInsList);
  };
}

export default Connect(Terms);
