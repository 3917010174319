import * as React from 'react';
import { css } from 'aphrodite/no-important';
import * as models from 'models/index';
import { Connect } from 'store/index';
import * as helpers from 'util/helpers';
import * as googleHelpers from 'util/google-helpers';
import * as constants from 'util/constants';
import { style } from './style';
import history from 'util/history';
import { withRouter } from 'react-router-dom';
import { LeftArrowIcon, RightArrowIcon } from 'util/icons';

class NavigationMenu extends React.Component<models.store.IAppProps> {
  private styles: any;

  private _handleNavigation(navigate: string) {
    googleHelpers.trackGoogleEvent(constants.GA_CATEGORIES.BUTTON_CLICK, constants.GA_EVENTS.NAVIGATION, navigate);

    if (navigate === constants.NAVIGATE.ALL) {
      history.push({
        pathname: '/',
        search: history.location.search,
      });
      return;
    }

    const categories = this.props.cmsData.data;
    const { category } = this.props.globalProps;
    const length = categories.length;
    let index = categories.findIndex((c: any) => category.id === c.id );

    index += (navigate === constants.NAVIGATE.PREV ? -1 : 1);
    index = (index + length) % length;

    const nextCategory = this.props.cmsData.data[index];
    const categoryName = helpers.normalizeForUrl(nextCategory.name);

    history.push({
      pathname: `/${categoryName}`,
      search: history.location.search,
    });

  };

  render() {
    const { navigation, global } = this.props.stylesData;
    const copy = this.props.copyData.text.navigation;

    const categories = this.props.cmsData.data;
    const { category } = this.props.globalProps;
    let index = categories.findIndex((c: any) => category.id === c.id );
    let prevCategoryName = index > 0 ? categories[index - 1].name : categories[categories.length - 1].name;
    let nextCategoryName = index < categories.length - 1 ? categories[index + 1].name : categories[0].name;

    this.styles = style({
      globalStyles: global,
      navigationStyles: navigation
    });

    return (
      <nav className={css(this.styles.navigation)} aria-label="Categories Navigation">
        <button className={css(this.styles.button)} onClick={() => this._handleNavigation(constants.NAVIGATE.PREV)} aria-label={ copy.prev_category_accessibility }>
          <LeftArrowIcon />
          <span className={css(this.styles.carouselButton)}> { prevCategoryName } </span>
        </button>

        <div className={css(this.styles.centerText)}>{category.name}</div>

        <button className={css(this.styles.button)} onClick={() => this._handleNavigation(constants.NAVIGATE.NEXT)} aria-label={ copy.next_category_accessibility }>
          <span className={css(this.styles.carouselButton)}> { nextCategoryName } </span>
          <RightArrowIcon />
        </button>
      </nav>
    );
  }
};

class AllCategoriesButton extends React.Component<models.store.IAppProps> {
  private styles: any;

  private _handleNavigation(navigate: string) {
    googleHelpers.trackGoogleEvent(constants.GA_CATEGORIES.BUTTON_CLICK, constants.GA_EVENTS.NAVIGATION, navigate);

    if (navigate === constants.NAVIGATE.ALL) {
      history.push({
        pathname: '/',
        search: history.location.search,
      });
      return;
    }

  };

  render() {
    const settings = this.props.cmsData.text.navigation;
    const { navigation, global } = this.props.stylesData;
    const copy = this.props.copyData.text.navigation;

    this.styles = style({
      globalStyles: global,
      navigationStyles: navigation
    });

    return (
      <>

        { copy.all_categories && !settings.all_categories.url &&
          <button className={css(this.styles.button, this.styles.allCatButton)} onClick={() => this._handleNavigation(constants.NAVIGATE.ALL)}>
            { copy.all_categories }
          </button> }

        { copy.all_categories && settings.all_categories.url &&
          <a href={settings.all_categories.url} className={css(this.styles.button, this.styles.allCatButton)} target={settings.all_categories.target || '_blank'}>
            { copy.all_categories }
          </a> }

      </>
    );
  }
};

export const Navigation = withRouter(Connect(NavigationMenu));
export const AllCatButton = withRouter(Connect(AllCategoriesButton));
