import * as models from 'models/index';
import { media } from 'util/style-variables';
import { StyleSheet } from 'aphrodite/no-important';

export const style: any = (styles: models.base.IGenericObject) =>
  StyleSheet.create({
    media_container: {
      padding: '30px 30px 26px',

      [media.tablet]: {
        padding: '0px'
      }
    },

    information_container: {
      padding: '0 40px 50px',
      ...styles.confirmationStyles.general,

      [media.tablet]: {
        paddingTop: '18px'
      }
    },

    headline: {
      paddingBottom: '0px',
      ...styles.globalStyles.headlines,
      ...styles.confirmationStyles.headline,

      [media.tablet]: {
        fontSize: '24px',
        marginBottom: 10,
        ...styles.globalStyles.headlines.tablet,
        ...styles.confirmationStyles.headline.tablet
      },

      [media.desktop]: {
        ...styles.globalStyles.headlines.desktop,
        ...styles.confirmationStyles.headline.desktop
      }
    },

    description_1: {
      lineHeight: '1.4',
      padding: 0,
      marginBottom: 20,
      ...styles.confirmationStyles.description_1,

      [media.tablet]: {
        marginBottom: 30
      }
    },

    description_2: {
      lineHeight: '1.4',
      paddingBottom: '10px',
      ...styles.confirmationStyles.description_2,

      [media.tablet]: {
        paddingBottom: '10px'
      }
    },

    twitter_options: {
      paddingTop: '15px'
    },

    twitter_headline: {
      ...styles.confirmationStyles.twitter.headline
    },

    twitter_description: {
      paddingBottom: '15px',
      ...styles.confirmationStyles.twitter.description
    }
  });
