import * as models from 'models/index';
import { media } from 'util/style-variables';
import { StyleSheet } from 'aphrodite/no-important';

export const style: any = (styles: models.base.IGenericObject) => StyleSheet.create({
  countdown_container: {
    padding: '10px 0 20px',
    textAlign: 'center'
  },

  timer_wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...styles.countdownStyles.clock
  },

  description: {
    paddingBottom: '16px',
    ...styles.globalStyles.headlines,
    ...styles.countdownStyles.description,

    [media.tablet]: {
      fontSize: '28px',
      paddingBottom: '20px',
      ...styles.globalStyles.headlines.tablet,
      ...styles.countdownStyles.description.tablet
    },

    [media.desktop]: {
      ...styles.globalStyles.headlines.desktop,
      ...styles.countdownStyles.description.desktop
    }
  },

  countdown_unit: {
    display: 'inline-flex',
    width: '56px',
    height: '56px',
    fontSize: '36px',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    ...styles.countdownStyles.countdownUnit
  },

  countdown_number: {
    padding: '0px',
    lineHeight: 1
  },

  countdown_label: {
    display: 'block',
    fontSize: '14px',
    ...styles.countdownStyles.labels
  },

  colon: {
    fontSize: '28px',
    padding: '0 5px',
    transform: 'translateY(-8%)',
    ...styles.countdownStyles.colon
  }
});
