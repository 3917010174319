import * as React from 'react';
import { css } from 'aphrodite/no-important';
import * as models from 'models/index';
import { Connect } from 'store/index';
import { IMG_LOADING } from 'util/constants';
import { style } from './style';

class Loading extends React.Component<models.store.IAppProps> {
  public render(): React.ReactNode {
    const styles = style();

    return (
      <section className={css(styles.loading)} aria-busy='true' aria-live='polite'>
        {this.props.loadingProps.title &&
          <h1 className={css(styles.loading_title)}> {this.props.loadingProps.title} </h1>}

        <img className={css(styles.loading_image)} src={IMG_LOADING} alt="loading" />

        {this.props.loadingProps.description &&
          <p className={css(styles.loading_description)}> {this.props.loadingProps.description} </p>}
      </section>
    );
  }
}

export default Connect(Loading);  
