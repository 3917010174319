import * as models from 'models/index';
import * as helpers from 'util/helpers';
import { appMaxWidth, media } from 'util/style-variables';
import { StyleSheet } from 'aphrodite/no-important';

export const style: any = (styles: models.base.IGenericObject) => StyleSheet.create({
  header: {
    backgroundColor: 'transparent',
    margin: '0 auto',
    maxWidth: helpers.checkIfTrue(styles.settings.display_bleed) ? 'none' : appMaxWidth,
    minHeight: '175px',
    position: 'relative',
    width: '100%',
    ...styles.headerStyles.general,

    [media.xsMobile]: {
      minHeight: '58px',
    },

    [media.mobile]: {
      ...styles.headerStyles.general,
    },

    [media.tablet]: {
      ...styles.headerStyles.general.tablet
    },

    [media.desktop]: {
      ...styles.headerStyles.general.desktop
    }
  },

  ad: {
    marginLeft: 'auto',
    marginTop: '15px',
    width: '88px',

    [media.tablet]: {
      width: '120px'
    }
  },

  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '0 auto',
    maxWidth: appMaxWidth,
    ...styles.globalStyles.container,

    [media.tablet]: {
      ...styles.globalStyles.container.tablet
    },

    [media.desktop]: {
      ...styles.globalStyles.container.desktop
    }
  },

  copy_wrapper: {
    marginTop: 'auto',
    minWidth: '100%',
    padding: '15px 0'
  },

  headline: {
    ...styles.globalStyles.headlines,
    ...styles.headerStyles.headline,
    paddingBottom: '0px',

    [media.tablet]: {
      fontSize: '40px',
      ...styles.globalStyles.headlines.tablet
    },

    [media.desktop]: {
      fontSize: '40px',
      ...styles.globalStyles.headlines.desktop
    }
  },

  description: {
    ...styles.headerStyles.description,

    [media.tablet]: {
      fontSize: '20px',
      ...styles.headerStyles.description.tablet
    },

    [media.desktop]: {
      ...styles.headerStyles.description.desktop
    }
  },

  logo: {
    margin: '15px 10px 0 0',
    ...styles.headerStyles.logo,

    [media.tablet]: {
      ...styles.headerStyles.logo.tablet
    },

    [media.desktop]: {
      ...styles.headerStyles.logo.desktop
    }
  }
});
