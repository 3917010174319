import * as models from 'models/index';
import { AppStateProvider } from 'store/index';
import pymLoader from 'util/pym';

export function setContestantId(this: AppStateProvider, id: string): void {
  const { globalProps } = this.state;

  this.setState((prevState: models.store.IAppProps) => {
    return {
      ...prevState,
      globalProps: {
        ...globalProps,
        contestantId: id
      }
    };
  });
};

export function setPym(this: AppStateProvider): Promise<any> | void {
  const pym = pymLoader();

  if (!pym) return;

  document.body.classList.add('iframed');

  this.setState((prevState: models.store.IAppProps) => {
    return {
      ...prevState,
      pym
    };
  });
}

export function scrollToTop(this: AppStateProvider): void {
  const { pym, cmsData } = this.state;
  const offsetSettings = cmsData.text.snapshot_settings.iframe_offset as models.base.IGenericObject;

  if (pym) {
    let offset = 0;

    if (offsetSettings) {
      Object.values(offsetSettings).forEach((item: models.base.IGenericObject) => {
        const breakpoint = parseInt(item.breakpoint,10);
        if (window.matchMedia(`(min-width: ${breakpoint}px)`).matches) {
          offset = parseInt(item.offset,10);
        }
      })
    }

    const pos = JSON.stringify({ x: 0, y: -offset });
    pym.sendMessage('scrollToChildPosition', pos);
  } else {
    window.scrollTo(0,0);
  }
}

export function storePosition(this: AppStateProvider): void {
  const { pym } = this.state;

  if (pym) {
    pym.sendMessage('storeCurrentPosition');
  }
}

export function scrollToStoredPosition(this: AppStateProvider): void {
  const { pym } = this.state;

  if (pym) {
    pym.sendMessage('scrollToStoredPosition');
  }
}
