import * as models from 'models/index';
import { StyleSheet } from 'aphrodite/no-important';
import { children, media } from 'util/style-variables';

export const style: any = (styles: models.base.IGenericObject) =>
  StyleSheet.create({
    share: {
      paddingTop: '25px',
      textAlign: 'center',

      [media.tablet]: {
        paddingTop: '30px',
      }
    },

    cta: {
      ...styles.shareStyles.cta,

      [media.tablet]: {
        ...styles.shareStyles.cta.tablet
      },

      [media.desktop]: {
        ...styles.shareStyles.cta.desktop
      }
    },

    social_button: {
      display: 'inline-block',
      transition: 'transform .2s',
      overflow: 'visible',

      [children('svg')]: {
        height: 24,
        overflow: 'visible'
      },

      ':active': {
        transform: 'scale(1.1)'
      },

      [media.hover]: {
        ':hover': {
          transform: 'scale(1.1)'
        }
      }
    },

    social_facebook: {
      [children('svg')]: {
        fill: '#3B5999',
        ...styles.shareStyles.icons
      }
    },

    social_twitter: {
      marginRight: '13px',
      [children('svg')]: {
        height: 22,
        fill: '#1DA1F2',
        ...styles.shareStyles.icons
      }
    }
  });
