import * as models from 'models/index';
import { AppStateProvider } from 'store/index';

export function closeModal(this: AppStateProvider): void {
  const { modalProps } = this.state;

  this.setState((prevState: models.store.IAppProps) => {
    return {
      ...prevState,
      modalProps: {
        type: '',
        nested: ''
      }
    };
  });
};

export function openModal(this: AppStateProvider, type: string): void {
  const { modalProps } = this.state;

  this.setState((prevState: models.store.IAppProps) => {
    return {
      ...prevState,
      modalProps: {
        type: type
      }
    };
  });
};


export function setNestedModal(this: AppStateProvider, type: string): void {
  const { modalProps } = this.state;

  this.setState((prevState: models.store.IAppProps) => {
    return {
      ...prevState,
      modalProps: {
        ...modalProps,
        nested: type
      }
    };
  });
};
