import * as React from 'react';
import * as models from 'models/index';
import * as helpers from 'util/helpers';
import { css } from 'aphrodite/no-important';

class CountdownUnit extends React.Component<models.countdown.ICountdownUnitProps> {

  render() {
    const styles = this.props.styles? this.props.styles: {}
    const { displayLabel, labels } = this.props;
    const label = this.props.number === '01'? labels.singular : labels.plural;
    const ariaHidden = this.props.ariaHidden;

    return (
      <div className={css(styles.countdown_unit)} aria-hidden={ariaHidden? ariaHidden: false}>
        <span className={css(styles.countdown_number)}> {this.props.number} </span>

        { helpers.checkIfTrue(displayLabel) &&
          <span className={css(styles.countdown_label)}> {label} </span> }

      </div>
    );
  }
}

export default CountdownUnit;
