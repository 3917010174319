import { useState } from 'react';
import { AppStateProvider } from 'store/index';
import { IModalFunctions } from 'models/modal'
import * as googleHelpers from 'util/google-helpers';
import * as helpers from 'util/helpers';
import * as constants from 'util/constants';
import * as models from 'models/index';
import api from 'util/api';

export function submitVote(this: AppStateProvider): Promise<IModalFunctions> {
  const { contestantId, category } = this.state.globalProps;
  const payload = this.state.apiFn.getVotePayload(contestantId, this.state.isCategoryVote,category.id);

  const gaAction = `${constants.GA_EVENTS.SUBMIT_VOTE}: ${category.id}: ${contestantId}`;
  googleHelpers.trackGoogleEvent(constants.GA_CATEGORIES.BUTTON_CLICK, gaAction, '');

  return api.postToVoteApi(payload, this.state.cmsData.settings.version_id)
  .then((response: any) => {
    if (response.response_code === constants.RESPONSE_CODES.GENERAL_INVALID) {
      this.state.modalFn.openModal(constants.MODAL_TYPES.errorGeneric);
    } else if (response.response_code === constants.RESPONSE_CODES.VALID) {
      const { globalProps } = this.state;
      const historyKey = `cat${globalProps.category.id}_votestring`;

      this.state.modalFn.openModal(constants.MODAL_TYPES.confirmation);

      if (response[historyKey]) {
        const history = JSON.parse(response[historyKey]);
        globalProps.category.voteHistory = history;

        return new Promise<any>((resolve: (value: any) => void | undefined) => {
          this.setState((prevState: models.store.IAppProps) => {
            return {
              ...prevState,
              globalProps
            };
          }, () => resolve(this.state));
        });
      }
    }
    else if (response.response_code === constants.RESPONSE_CODES.OVERLIMIT) {
      this.state.modalFn.openModal(constants.MODAL_TYPES.errorOverlimit);
    }
    else if (response.response_code === constants.RESPONSE_CODES.OUT_OF_WINDOW) {
      this.state.modalFn.openModal(constants.MODAL_TYPES.errorWindow);
    }
    else {
      this.state.modalFn.openModal(constants.MODAL_TYPES.errorGeneric);
    }
  })
  .catch(() => {
    this.state.modalFn.openModal(constants.MODAL_TYPES.errorGeneric);
  });
}

export function useVoteAttempt(userData: any) {
  const { isAuthorized, email } = userData
  const [voteAttempt, setVoteAttempt]:[boolean, any] = useState(false);

  const isUserValid = isAuthorized && email

  return { voteAttempt, setVoteAttempt, isUserValid }
}


export async function setVoteHistory(this: AppStateProvider): Promise<any> {
  const { globalProps, cmsData, apiFn, isCategoryVote } = this.state;
  const { userData, category } = globalProps;
  const displayVoteHistory = helpers.checkIfTrue(cmsData.text.grid.settings.display_vote_count) ||
                             helpers.checkIfTrue(cmsData.text.nominee.settings.display_vote_count);

  if (!isCategoryVote || !userData.isAuthorized || !category?.id || !displayVoteHistory) return;

  const payload = apiFn.getHistoryPayload(category.id);
  const response = await api.postToVoteApi(payload, cmsData.settings.version_id);

  if (response && response.response_code === constants.RESPONSE_CODES.VALID) {
    const key = `cat${category.id}_votestring`;
    const voteString = response[key];
    const history = voteString.length? JSON.parse(voteString) : {};
    category.voteHistory = history;
  }

  return new Promise<any>((resolve: (value: any) => void | undefined) => {
    this.setState((prevState: models.store.IAppProps) => {
      return {
        ...prevState,
        globalProps
      };
    }, () => resolve(this.state));
  });

  // Sample response
  // { cat5_votestring: "{"cat5-total":4,"cat5-A5":4}" response_code: "20" }
}
