import * as models from 'models/index';
import { appMaxWidth, darkenColor, media } from 'util/style-variables';
import { StyleSheet } from 'aphrodite/no-important';

export const style: any = (styles: models.base.IGenericObject) => StyleSheet.create({
  footer: {
    backgroundColor: '#aaa',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    textAlign: 'center',

    margin: '0 auto',
    maxWidth: appMaxWidth,
    width: '100%',
    minHeight: '40px',
    ...styles.footerStyles.general,

    [media.tablet]: {
      minHeight: '60px',
      ...styles.footerStyles.general.tablet
    }
  },

  container: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'repeat(3, auto)',
    alignItems: 'center',
    height: '100%',
    maxWidth: appMaxWidth,
    margin: 'auto',
    width: '100%',
    padding: '15px',
   ...styles.footerStyles.container,

    [media.tablet]: {
      ...styles.footerStyles.container.tablet,
      padding: '20px 20px 10px',
    },

    [media.desktop]: {
      ...styles.footerStyles.container.desktop
    }
  },

  footer_link: {
    padding: 0,
    margin: 0,
    textDecoration: 'none',
    ...styles.footerStyles.link,

    [media.tablet]: {
      ...styles.footerStyles.link.tablet
    },

    [media.desktop]: {
      ...styles.footerStyles.link.desktop
    },

    ':hover': {
      color: darkenColor(styles.footerStyles.link.color)
    },

    ':focus': {
      color: darkenColor(styles.footerStyles.link.color)
    }
  },

  separator: {
    ...styles.footerStyles.separator,
    paddingBottom: 0,

    ':last-child': {
      display: 'none'
    },

    [media.tablet]: {
      ...styles.footerStyles.separator.tablet
    },

    [media.desktop]: {
      ...styles.footerStyles.separator.desktop
    },
  }
});
