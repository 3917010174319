import * as React from 'react';
import { css } from 'aphrodite/no-important';
import * as models from 'models/index';
import { Connect } from 'store/index';
import Video from 'components/video';
import * as constants from 'util/constants';
import * as googleHelpers from 'util/google-helpers';
import { style } from './style';

class Closed extends React.Component<models.store.IAppProps> {

  componentDidMount() {
    googleHelpers.trackGooglePage(constants.GA_PAGES.CLOSED);
  }

  render() {
    const { media } = this.props.cmsData.text.closed;
    const { copy } = this.props.copyData.text.closed;
    const styles = style({
      closedStyles: this.props.stylesData.closed,
      globalStyles: this.props.stylesData.global
    });
    return (
      <div className={css(styles.closed)}>
        <h1 className={css(styles.headline)} dangerouslySetInnerHTML={{ __html: copy.headline }} />
        <p className={css(styles.description)} dangerouslySetInnerHTML={{ __html: copy.description }} />

        {this.props.children}

        { media.image && !media.video &&
          <img src={media.image} alt={copy.image_alt_text} /> }

        { media.video &&
          <div className={css(styles.video_wrapper)}>
            <Video url={media.video} />
          </div> }
      </div>
    );
  }
}

export default Connect(Closed);
