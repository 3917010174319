import * as models from 'models/index';
import * as constants from 'util/constants';
import * as helpers from 'util/helpers';
import history from 'util/history';
import { AppStateProvider } from 'store/index';
import {
  RANDOM,
  RANDOMIZED,
  ALPHA,
  ALPHABETICAL,
  SORT_BY,
  ASCENDING,
  RANDOMIZE,
} from 'util/constants';
import { SortingMethod, SortingDirection } from 'models/grid';

export function toggleSort(
  this: AppStateProvider,
  sortingMethod: SortingMethod,
  sortingDirection: SortingDirection = ASCENDING
): Promise<any> | null {
  const { gridProps, globalProps } = this.state;

  if (!globalProps.category?.contestants) return null;

  const method = sortingMethod.toLowerCase() as SortingMethod;
  const direction = sortingDirection.toLowerCase() as SortingDirection;
  const contestantOption = Object.values(SORT_BY).includes(method)
    ? method
    : null;

  interface Contestant {
    name: string
  }

  switch (method) {
    case contestantOption:
      globalProps.category.contestants = helpers.sortArrByProp(
        globalProps.category.contestants,
        method,
        direction
      );
      gridProps.sortingMethod = (contestantOption ||
        SORT_BY.NAME) as SortingMethod;
      break;
    case RANDOM:
    case RANDOMIZE:
    case RANDOMIZED:
      globalProps.category.contestants = helpers.shuffleArr(globalProps.category.contestants);
      gridProps.sortingMethod = RANDOMIZED;
      break;
    case ALPHABETICAL:
    case ALPHA:
    default:
      var contestants = globalProps.category.contestants.map((contestant: Contestant) => contestant.name?.toLowerCase());
      contestants = helpers.sortArrByProp(
        globalProps.category.contestants,
        SORT_BY.NAME,
        direction
      );
      gridProps.sortingMethod = ALPHABETICAL;
  }

  gridProps.sortingDirection = direction;

  return new Promise<any>((resolve: (value: any) => void | undefined) => {
    this.setState((prevState: models.store.IAppProps) => {
      return {
        ...prevState,
        gridProps,
        globalProps
      };
    }, () => resolve(this.state));
  });
};

export async function setCategory(this: AppStateProvider, params: { name?: string, detail?: string} = {}): Promise<any> {
  // TODO fix types
  const { globalProps, cmsData, contestantsData } = this.state;
  const name = params.name;
  const detail = params.detail;

  let newCategory = globalProps.category;

  if (name) {
    newCategory = Object.values(cmsData.data).find((cat: any) => {
      const nameUrl = helpers.normalizeForUrl(cat['name']);
      if ( nameUrl === name ) return cat;
    })
  }

  if (newCategory?.name) {
    globalProps.category = newCategory;
    await this.state.gridFn.createContestantList();
  } else {
    history.push({
      pathname: '/',
      search: history.location.search,
    });
    return;
  }

  if (detail) {
    const contestant = contestantsData.data.find((contestant: any) => {
      return helpers.normalizeForUrl(contestant.name) === detail
    });

    globalProps.contestantId = contestant?.id || '';
  }

  return new Promise<any>((resolve: (value: any) => void | undefined) => {
    this.setState((prevState: models.store.IAppProps) => {
      return {
        ...prevState,
        globalProps: {
          ...globalProps,
          category: newCategory
        }
      };
    }, () => resolve(this.state));
  });
}

export function createContestantList(this: AppStateProvider): Promise<any> {
  const { globalProps } = this.state;
  const categoryId = globalProps.category.id;
  const contestants = this.state.contestantsData.data;
  const displayEliminated = helpers.checkIfTrue(this.state.cmsData.text.grid.settings.display_eliminated);

  globalProps.category.contestants = contestants.filter((contestant: models.cms.ICmsOptions) => {
    return categoryId === contestant.category_id &&
      (displayEliminated? !helpers.checkIfTrue(contestant.is_eliminated) : true);
  });


  return new Promise<any>((resolve: (value: any) => void | undefined) => {
    this.setState((prevState: models.store.IAppProps) => {
      return {
        ...prevState,
        globalProps
      };
    }, () => resolve(this.state));
  });
}

export function getContestantById(this: AppStateProvider, id: string): any {
  const { data } = this.state.contestantsData;
  return data.find((c: any) => {
    if (c.id === id) return c;
  })
}

export function displayContestantModal(this: AppStateProvider, id: string) {
  const { display_confirmation } = this.state.cmsData.text.grid.settings;

  if (!helpers.checkIfTrue(display_confirmation) || !id) {
    const categoryName = helpers.normalizeForUrl(this.state.globalProps.category.name);
    return history.push({
      pathname: `/${categoryName}`,
      search: history.location.search,
    });
  }

  this.state.modalFn.openModal(constants.MODAL_TYPES.vote);
};
