import * as React from 'react';
import { css } from 'aphrodite/no-important';
import * as models from 'models/index';
import { Connect } from 'store/index';
import { style } from './style';

class Header extends React.Component<models.store.IAppProps> {
  render() {
    const { description, headline, logo } = this.props.copyData.text.header;

    const { stylesData } = this.props;

    const styles = style({
      globalStyles: stylesData.global,
      headerStyles: stylesData.header,
      settings: { ...stylesData.header.settings }
    });

    return (
      <header className={css(styles.header)} role='banner'>
        <div className={css(styles.container)}>

          { logo.image &&
            <a className={css(styles.logo)} target='_blank' href={logo.link}>
              <img alt={logo.alt_text} src={logo.image} />
            </a> }

          <div className={css(styles.ad)}>
            {this.props.children}
          </div>

          { (headline || description) &&
            <div className={css(styles.copy_wrapper)}>

              { headline &&
              <h1 className={css(styles.headline)}>{headline}</h1> }

              { description &&
              <p className={css(styles.description)}>{description}</p> }

          </div> }

        </div>
      </header>
    );
  }
}

export default Connect(Header);
