import * as React from 'react';
import { css } from 'aphrodite/no-important';
import * as models from 'models/index';
import { Connect } from 'store/index';
import * as constants from 'util/constants';
import * as fbHelpers from 'util/fb_helpers';
import * as googleHelpers from 'util/google-helpers';
import * as helpers from 'util/helpers';
import { login } from 'util/twitter';
import { FacebookIcon, TwitterIcon } from 'util/icons';
import { storageFactory } from 'util/storage_helpers';
import { style } from './style';

const localStore = storageFactory(localStorage);

class Login extends React.Component<models.store.IAppProps & models.login.ILoginSwap> {
  loaderTimeout: number = 5000;

  state = ({
    email: '',
    hasEmailError: false,
    hasFbPermissionsError: false,
    isLoading: true
  });

  UNSAFE_componentWillMount(): void {
    const { email, method } = this.props.globalProps.userData;
    this._storeTermNames();

    if (this.props.isNested && this.props.modalProps.nested !== constants.MODAL_TYPES.login) {
      this.props.modalFn.setNestedModal(constants.MODAL_TYPES.login);
    }

    if(!this.state.hasFbPermissionsError &&
       (method === constants.AUTH_METHODS.FACEBOOK && !email)) {

      this.setState({ hasFbPermissionsError: true });
      this.props.loginFn.updateRetryFb(false);
    }
  }

  componentDidUpdate(): void {
    if (this.state.hasFbPermissionsError && this.props.loginProps.shouldRetryFb) {
      this.props.loginFn.updateRetryFb(false);
      this._handleFacebookLogin();
    }
    if (this.props.termsProps && this.state.isLoading) {
      this.setState({ isLoading: false });
    }
  }

  componentWillUnmount(): void {
    clearTimeout(this.loaderTimeout);
  }

  render(): React.ReactNode {
    const { settings } = this.props.cmsData.text.login;
    const copy = this.props.copyData.text.login;

    if (this.state.isLoading) {
      return <this.props.SwapLoading />;
    }
    const areButtonsEnabled = Object
      .keys(this.props.termsProps)
      .every(term => {
        return (this.props.termsProps[term].isChecked && this.props.termsProps[term].isRequired)
          || !this.props.termsProps[term].isRequired;
      });
    const styles = style({
      globalStyles: this.props.stylesData.global,
      loginStyles: this.props.stylesData.login,
      buttonStyles: this.props.stylesData.login.buttons,
      hasEmailError: this.state.hasEmailError,
      areButtonsEnabled
    });

    if (this.state.hasFbPermissionsError && !this.props.loginProps.shouldRetryFb) {
      return <this.props.SwapError />;
    }
    return (
      <div
        aria-label='Log in'
        className={css(styles.login)}>
        <h2 className={css(styles.headline)}>{copy.headline}</h2>

        <div className={css(styles.loginForm)}>

        {this.props.children}

        <div className={css(styles.buttonsGroup)}>

        { helpers.checkIfTrue(settings.display_facebook) &&
          helpers.checkIfTrue(settings.is_email_enabled) &&
          <h3>{copy.sign_in_options}</h3>}

        { helpers.checkIfTrue(settings.display_facebook) &&
          <button
            aria-label='Sign in with Facebook'
            className={css(styles.button, styles.facebook_button)}
            aria-disabled={!areButtonsEnabled}
            onClick={areButtonsEnabled? this._handleFacebookLogin : undefined}>
            <FacebookIcon />
            { copy.buttons.facebook }
          </button>}

          { helpers.checkIfTrue(settings.display_twitter) &&
          <button
            aria-label='Sign in with Twitter'
            className={css(styles.button, styles.twitter_button)}
            onClick={areButtonsEnabled? this._twitterLogin : undefined}
            aria-disabled={!areButtonsEnabled}>
            <TwitterIcon />
            { copy.buttons.twitter }
          </button> }

        {helpers.checkIfTrue(settings.display_facebook) &&
          helpers.checkIfTrue(settings.display_email) &&
          <div className={css(styles.divider)}>
            <div className={css(styles.divider_line)}></div>
            <div className={css(styles.divider_text)}>OR</div>
            <div className={css(styles.divider_line)}></div>
          </div>}

        {helpers.checkIfTrue(settings.display_email) &&
          <form onSubmit={this._handleEmailLogin}>
            <p className={css(styles.email_cta)}>
              {copy.email_cta}
            </p>

            <input
            className={css(styles.email_input)}
            type="email"
            maxLength={40}
            name="email-address"
            onChange={this._handleInput}
            placeholder={ copy.email_input_placeholder } />

            <p className={css(styles.email_error)} role='alert' aria-live='assertive'>
              { copy.email_error }
            </p>
            <button
              aria-live='assertive'
              aria-label='Sign in with Email'
              className={css(styles.button, styles.email_button)}
              aria-disabled={!areButtonsEnabled}
              onClick={areButtonsEnabled? this._handleEmailLogin : undefined}>
              { copy.buttons.email }
            </button>
          </form> }

          </div>

          </div>

      </div>
    )
  }

  private _twitterLogin = async () => {
    try {
      googleHelpers.trackGoogleEvent(constants.GA_CATEGORIES.BUTTON_CLICK, constants.GA_EVENTS.TWITTER_LOGIN,'');

      const { twitter_auth_key, twitter_auth_url } = this.props.cmsData.settings;
      const twitterPayload = await login(twitter_auth_key, twitter_auth_url);
      await this.props.authFn.loginViaTwitter({ token: twitterPayload, ...twitterPayload.user });

      const payload = JSON.stringify({ [twitterPayload.token]: { ...this.props.termsProps } });
      localStore.setItem(constants.TWITTER_LOCALSTORAGE_LABEL, payload);

    } catch(e) {
      console.log('twitter login failed', e)
    }
  }

  private _handleEmailLogin = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!this.state.email.match(constants.EMAIL_REGEX)) {
      return this.setState({ hasEmailError: true });
    }

    const payload = JSON.stringify({ [this.state.email]: { ...this.props.termsProps } });
    localStore.setItem(constants.EMAIL_LOCALSTORAGE_LABEL, payload);

    await this.props.authFn.loginViaEmail(this.state.email);
    this.props.authFn.submitLogin()
    googleHelpers.trackGoogleEvent(constants.GA_CATEGORIES.BUTTON_CLICK, constants.GA_EVENTS.EMAIL_LOGIN,'');
  };

  private _handleFacebookLogin = () => {
    this.props.loadingFn.setTitle('Processing...');
    this.props.loadingFn.setDescription('');
    this.setState({ isLoading: true });

    this._manageLoader();

    // AMA-746 FB login triggers a re-render of auth modal, which resets terms,
    // so we'll save it here.
    const terms = JSON.parse(JSON.stringify(this.props.termsProps));

    return window.FB.login(() => {
      this._resetLoader();

      googleHelpers.trackGoogleEvent(constants.GA_CATEGORIES.BUTTON_CLICK, constants.GA_EVENTS.FACEBOOK_LOGIN,'');

      fbHelpers.checkLoginState()
        .then(response => {
          if (!response.email) {
            return window.FB.logout(() => {
              this.setState({ hasFbPermissionsError: true });
            });
          }

          const payload = JSON.stringify({ [response.email]: { ...terms } });
          this.props.termsFn.updateTerms( terms );
          localStore.setItem(constants.FACEBOOK_LOCALSTORAGE_LABEL, payload);
          this.props.authFn.loginViaFacebook(response);
        })
        .catch(() => {
          //Do nothing
        });
    },
      {
        auth_type: constants.FACEBOOK_AUTH_TYPES.REREQUEST,
        scope: this.props.cmsData.social.fb.scope
      });
  };

  private _handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      email: e.currentTarget.value.toLowerCase(),
      hasEmailError: false
    });
  };

  private _manageLoader = () => {
    this.setState({ isLoading: true });

    this.props.loadingFn.setDescription('');

    this.loaderTimeout = window.setTimeout(() => {
      this.props.loadingFn.setDescription('Facebook is taking too long to get your information');
    }, 5000);
  };

  private _resetLoader = () => {
    this.setState({ isLoading: false });

    this.props.loadingFn.setTitle('');
    this.props.loadingFn.setDescription('');

    clearTimeout(this.loaderTimeout);
  }

  private _getOptInsList = () => {
    return Object.keys(this.props.cmsData.text.login.terms).filter(category => {
      return category.substring(0, 7) === 'opt_in_';
    })
      .sort();
  };

  private _storeTermNames = () => {
    const optInsList = this._getOptInsList()
      .reduce((storage, optInName) => {
        const optInSettings = this.props.cmsData.text.login.terms[optInName].settings;
        const optInCopy = this.props.copyData.text.login.terms[optInName];

        storage[optInCopy.name] = {};
        storage[optInCopy.name].isChecked = helpers.checkIfTrue(optInSettings.prechecked);
        storage[optInCopy.name].isRequired = helpers.checkIfTrue(optInSettings.required);
        return storage;
      }, Object.create(null));

    this.props.termsFn.updateTerms(optInsList);
  };
}

export default Connect(Login);
