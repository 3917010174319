import * as React from 'react';
import { useEffect } from 'react';
import * as models from 'models/index';
import { Connect } from 'store/index';
import { RightArrowIcon, LeftArrowIcon } from 'util/icons';
import Button from 'components/button';
import Video from 'components/video';
import * as constants from 'util/constants';
import * as helpers from 'util/helpers';
import * as googleHelpers from 'util/google-helpers';
import history from 'util/history';
import { css } from 'aphrodite/no-important';
import { style } from './style';
import { useVoteAttempt } from 'store/vote';

type VoteProps = models.store.IAppProps & models.vote.IVoteSwap & models.global.IGenericProps;

function Vote(props: VoteProps) {
  const { voteAttempt, setVoteAttempt, isUserValid } = useVoteAttempt(props.globalProps.userData);

  const { contestantId } = props.globalProps;
  const contestant = props.gridFn.getContestantById(contestantId);
  const copy = props.copyData.text.confirmation;

  const styles = style({
    globalStyles: props.stylesData.global,
    voteStyles: props.stylesData.confirmation
  });

  useEffect(() => {

    const { contestantId } = props.globalProps;
    const contestant = props.gridFn.getContestantById(contestantId);
    const contestantName = helpers.normalizeForUrl(contestant.name);
    const categoryName = helpers.normalizeForUrl(props.globalProps.category.name);
    const pathname = `/${categoryName}/${contestantName}`

    googleHelpers.trackGooglePage(pathname);

    history.push({
      pathname,
      search: history.location.search,
    });

    document.addEventListener('keydown', _handleKeyDown);

    if (voteAttempt && isUserValid) {
      props.voteFn.submitVote();
    }

    return () => {
      document.removeEventListener('keydown', _handleKeyDown);

      history.push({
        pathname: `/${categoryName}`,
        search: history.location.search,
      });
    };
  }, [voteAttempt])

  if (voteAttempt && !isUserValid) {
    return props.SwapLogin;
  }

  const externalLinkLabel = copy.link_accessibility?.replace('{{NAME}}', contestant.name);

  return (
      <section className={css(styles.vote)} aria-label='Confirm your vote' aria-roledescription='carousel'>
      <div className={css(styles.media_container)}>
        { contestant.image && !contestant.video &&
            <img src={contestant.image} alt={contestant.name} /> }

        { contestant.video &&
          <div className={css(styles.video)}>
            <div className={css(styles.video_wrapper)}>
              <Video url={contestant.video} />
            </div>
          </div> }
      </div>

      <div className={css(styles.info_container)}>

        { contestant.nominee_headline &&
          <p className={css(styles.headline)}> {contestant.nominee_headline} </p> }

        { !contestant.nominee_headline && copy.universal_headline && helpers.checkIfTrue(props.cmsData.settings.window_status) &&
          <p className={css(styles.headline)}> {copy.universal_headline} </p> }

        <div aria-live="polite" aria-atomic="true" role="region">
          { contestant.name &&
            <h1 className={css(styles.name)} dangerouslySetInnerHTML={{ __html: contestant.name }}/> }
        </div>

        { contestant.description_1 &&
          <p className={css(styles.description_1)}>
            {contestant.description_1}
          </p> }

        { contestant.description_2 &&
          <p className={css(styles.description_2)}>
            {contestant.description_2}
          </p> }

        { contestant.description_3 &&
          <p className={css(styles.description_3)}>
            {contestant.description_3}
          </p> }
      </div>

      <div className={css(styles.cta_container)}>

        { contestant.link_url && copy.link &&
          <a href={contestant.link_url} target="_blank" rel="noreferrer" className={css(styles.link)} aria-label={externalLinkLabel}> { copy.link } </a> }

        {helpers.checkIfTrue(props.cmsData.settings.window_status) &&
          <Button aria-label='Submit Votes'
            buttonData={copy.buttons.vote}
            buttonStyles={props.stylesData.confirmation.buttons.vote}
            options={{ globalStyles: props.stylesData.global.buttons }}
            onClick={() => _handleClick()} />}

      </div>

      {props.children}

      <button
        aria-label='Previous contestant'
        className={css(styles.nav_arrow, styles.nav_prev)}
        onClick={() => _scrollTargetIndex('left')}>
        <LeftArrowIcon />
      </button>

      <button
        aria-label='Next contestant'
        className={css(styles.nav_arrow, styles.nav_next)}
        onClick={() => _scrollTargetIndex('right')}>
        <RightArrowIcon />
      </button>
    </section>
  );

  function _handleClick() {
    if (!isUserValid) {
      return setVoteAttempt({ voteAttempt: true });
    }

    setVoteAttempt({ voteAttempt: false });
  }

  function _handleKeyDown(e: any) {
    switch (e.keyCode) {
      case constants.KEYS.LEFT:
        _scrollTargetIndex('left');
        break;

      case constants.KEYS.RIGHT:
        _scrollTargetIndex('right');
        break;

      default:
        break;
    }
  }

  function _scrollTargetIndex(direction: string) {
    const length = props.globalProps.category.contestants.length;

    let { contestantId } = props.globalProps;
    let index = props.globalProps.category.contestants.findIndex((c: any) => contestantId === c.id );

    index += (direction === 'left' ? -1 : 1);
    index = (index + length) % length;

    const nextContestant = props.globalProps.category.contestants[index];

    props.globalFn.setContestantId(nextContestant.id);

    const contestantName = helpers.normalizeForUrl(nextContestant.name);
    const categoryName = helpers.normalizeForUrl(props.globalProps.category.name);

    history.push({
      pathname: `/${categoryName}/${contestantName}`,
      search: history.location.search,
    });
  };

}

export default Connect(Vote);
