import * as React from 'react';
import * as models from 'models/index';
import { Connect } from 'store/index';
import * as helpers from 'util/helpers';
import * as constants from 'util/constants';
import { css } from 'aphrodite/no-important';
import CountdownUnit from './countdown-unit';
import { style } from './style';

class Countdown extends React.Component<models.store.IAppProps> {

  public state = {
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
    displayDays: false,
    displayHours: false,
    displayMinutes: false,
    displaySeconds: false
  }

  public timer: any = null;

  render() {
    const { settings } = this.props.cmsData.text.countdown_clock;
    const copy = this.props.copyData.text.countdown_clock;
    
    const { days, hours, minutes, seconds,
      displayDays, displayHours, displayMinutes, displaySeconds } = this.state;
    const styles = style({
      globalStyles: this.props.stylesData.global,
      countdownStyles: this.props.stylesData.countdownClock
    });

    return (
      <section className={css(styles.countdown_container)}>
        <h2 className={css(styles.description)}> {copy.description} </h2>

        <div className={css(styles.timer_wrapper)} aria-live='assertive' aria-atomic='true'>
          {displayDays &&
            <CountdownUnit styles={styles} number={days} displayLabel={settings.display.labels} labels={copy.labels.days} />}

          {displayDays && displayHours &&
            <span className={css(styles.colon)}> : </span>}

          {displayHours &&
            <CountdownUnit styles={styles} number={hours} displayLabel={settings.display.labels} labels={copy.labels.hours} />}

          {displayMinutes &&
            <span className={css(styles.colon)}> : </span>}

          {displayMinutes &&
            <CountdownUnit styles={styles} number={minutes} displayLabel={settings.display.labels} labels={copy.labels.minutes} />}

          {displaySeconds &&
            <span className={css(styles.colon)}> : </span>}

          {displaySeconds &&
            <CountdownUnit styles={styles} number={seconds} displayLabel={settings.display.labels} labels={copy.labels.seconds} ariaHidden={true}/>}
        </div>

      </section>
    );
  }

  UNSAFE_componentWillMount() {
    const { settings } = this.props.cmsData.text.countdown_clock;
    const displayUnits = {
      displayDays: helpers.checkIfTrue(settings.display.days),
      displayHours: helpers.checkIfTrue(settings.display.hours),
      displayMinutes: helpers.checkIfTrue(settings.display.minutes),
      displaySeconds: helpers.checkIfTrue(settings.display.seconds)
    }

    this.setState({ ...displayUnits });

    this.updateCountdown();

  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  updateCountdown() {
    const { copy } = this.props.cmsData.text.countdown_clock;

    const endDate = new Date(`${copy.month_name} ${copy.day_number} ${copy.year_number} ${copy.hours_number}:${copy.minutes_number}:${copy.seconds_number} ${copy.meridiem} ${copy.timezone} `)
    const currentDate = new Date();
    const timeDiff = (endDate.getTime() - currentDate.getTime()) / 1000;

    if (isNaN(timeDiff) || timeDiff <= 0) {
      return;
    }

    const time = this.convertTimeToCountdown(timeDiff);
    this.setState({ ...time });
    this.timer = setTimeout(this.updateCountdown.bind(this), 1000);

  }

  convertTimeToCountdown(remaining: any) {
    let { days, hours, minutes, seconds } = this.state;
    const { displayDays, displayHours, displayMinutes, displaySeconds } = this.state;
    const { DAY, HOUR, MINUTE, SECOND } = constants;

    if (displayDays) {
      days = this.formatTimeValue(Math.floor(remaining / DAY));

      remaining %= DAY;
    }

    if (displayHours) {
      hours = this.formatTimeValue(Math.floor(remaining / HOUR));

      remaining %= HOUR;
    }

    if (displayMinutes) {
      minutes = this.formatTimeValue(Math.floor(remaining / MINUTE));

      remaining %= MINUTE;
    }

    if (displaySeconds) {
      seconds = this.formatTimeValue(Math.floor(remaining / SECOND));
    }

    return { days, hours, minutes, seconds };

  }

  formatTimeValue(val: number): string {

    const str = val.toString();
    const shouldModify = str.length < 2;

    return shouldModify ? (`00${str}`).slice(-2) : str;

  }
}

export default Connect(Countdown);
