import * as models from 'models/index';
import { children, darkenColor } from 'util/style-variables';
import { StyleSheet } from 'aphrodite/no-important';

export const style: any = (styles: models.base.IGenericObject) =>
  StyleSheet.create({
    fbPermissions: {
      textAlign: 'center',
      padding: '40px'
    },

    headline: {
      ...styles.fbErrorStyles.headline
    },

    description: {
      ...styles.fbErrorStyles.description
    },

    facebook_button: {
      fontSize: '16px',
      margin: '10px auto',
      padding: '8px',
      width: '100%',
      minHeight: '40px',
      ...styles.buttonStyles.default,

      [children('svg')]: {
        fill: '#fff',
        height: '20px',
        transform: 'translateY(3px)'
      },

      [children('> span')]: {
        marginRight: '15px'
      },

      ':hover': {
        background: darkenColor(styles.buttonStyles.background),
      },

      ':focus': {
        background: darkenColor(styles.buttonStyles.background),
      },
    }
  });
